import { IIssueActivityItem, IIssueComment, IssueActivityType } from "@/types/issue";
import { Divider, Flex, Text, useTheme } from "@chakra-ui/react";

import styles from "./styles.module.scss";
import VersionChange from "./VersionChange/version_change";
import Comment from "./Comment/comment";
import CommentInput from "./CommentInput/comment_input";
import { useContext } from "react";
import { IssueDetailContext } from "@/views/IssueDetailPage/issue_detail_page";

const IssueActivity = (): JSX.Element => {
  const theme = useTheme();
  const issueDetailContext = useContext(IssueDetailContext);
  const { activity } = issueDetailContext;

  const ActivityItem = (props: { item: IIssueActivityItem }): JSX.Element => {
    const { item } = props;
    if (item.activityType === IssueActivityType.COMMENT) {
      return (
       <Comment comment={item.activityObject as IIssueComment} /> 
      )
     }

    if (item.activityType === IssueActivityType.VERSION_CHANGE) {
      return (
        <VersionChange versionChange={item.activityObject as IIssueVersionChange} /> 
      )
    }

    return (<></>);
  }

  return (
    <Flex className={styles.sectionContainer} flexDirection="column">
      <Text className={styles.sectionHeader}>Activity</Text>
      <Flex
        borderRadius="12"
        flex={1}
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        flexDirection="column"
        mt={0}
        p={6}
      >
        <Flex flexDirection="column">
          <Text className={styles.allActivityHeader}>Add Comment</Text>
          <CommentInput />
        </Flex>

        <Divider my={6}/>

        <Flex flexDirection="column">
          <Text className={styles.allActivityHeader}>All Activity</Text>
          { (activity || []).map((item: IIssueActivityItem) => <ActivityItem item={item} />) }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default IssueActivity;
