import { Box, Flex, SimpleGrid, Text, useTheme } from "@chakra-ui/react";

import { formatPhoneNumber } from "@/utils/formatting";

import styles from "./styles.module.scss";

function dayOfWeekAsString(dayIndex) {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"  // We include Sunday twice to allow for 0 and 1 indexed days of week
  ][dayIndex] || '';
}

const AssignedCleanersSection = ({ schedule, users }) => {
  const theme = useTheme();

  return (
    <Flex flexDirection={"column"}>
      <Text
        fontSize={"sm"}
        fontWeight={"medium"}
        color={theme.colors.secondary.darkGray}
        marginBottom={"1em"}
      >ASSIGNED CLEANERS</Text>
      {Object.entries(schedule).map(([employeeId, employeeSchedule]) => {
        const cleaner = users[employeeId]
        const data = {
          "Name": cleaner.name,
          "Phone Number": formatPhoneNumber(cleaner.phoneNumber),
          "Schedule": Object.entries(employeeSchedule)
            .map(([range, days]) =>
              `${range} on ${days
                .sort(function (a, b) { return a - b })
                .map(day => dayOfWeekAsString(day).slice(0, 3)).join(", ")}`
            )
            .join("\n")
        }

        return (
          <Flex key={employeeId} flexDirection={"column"} marginBottom={"1.5em"}>
            {
              Object.entries(data).map(([section, value]) => (
                <Flex key={section} flexDirection={"row"}>
                  <Text
                    color={theme.colors.secondary.deepGray}
                    marginBottom={"0.5em"}
                    minWidth={"40%"}
                  >{section}
                  </Text>
                  <Text fontWeight={"medium"}>{value}</Text>
                </Flex>
              ))
            }
          </Flex>
        )
      })
      }
    </Flex>
  )
}

const BuildingInfo = ({ data }) => {
  const theme = useTheme();
  const generalInfo = {
    "Name": data.location.name,
    "Address": data.location.address,
    "Supervisor": data.location.supervisors.length > 0 ?
      data.location.supervisors
        .map(key => data.users[key].name)
        .join(", ")
      : "-",
  }
  const contactInfo = !!data.location?.contactInfo && {
    "Name": data.location.contactInfo?.name || "-",
    "Phone Number": formatPhoneNumber(data.location.contactInfo?.phoneNumber) || "-"
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.75em"}
      width={"100%"}
    >
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacingX={"1em"} spacingY={"2.5em"}>
        <Flex flexDirection={"column"}>
          <Box>
            <Text
              fontSize={"sm"}
              fontWeight={"medium"}
              color={theme.colors.secondary.darkGray}
              marginBottom={"1em"}
            >GENERAL INFO</Text>
            {
              Object.entries(generalInfo).map(([section, value]) => (
                <Flex key={section} flexDirection={"row"}>
                  <Text
                    color={theme.colors.secondary.deepGray}
                    marginBottom={"0.5em"}
                    minWidth={"40%"}
                  >{section}
                  </Text>
                  <Text fontWeight={"medium"}>{value}</Text>
                </Flex>
              ))
            }
          </Box>
          {!!contactInfo &&
            <Box marginTop={"2em"}>
              <Text
                fontSize={"sm"}
                fontWeight={"medium"}
                color={theme.colors.secondary.darkGray}
                marginTop={"2.5em"}
                marginBottom={"1em"}
              >BUILDING CONTACT</Text>
              {Object.entries(contactInfo).map(([section, value]) => (
                <Flex key={section} flexDirection={"row"}>
                  <Text
                    color={theme.colors.secondary.deepGray}
                    marginBottom={"0.5em"}
                    minWidth={"40%"}
                  >{section}
                  </Text>
                  <Text fontWeight={"medium"}>{value}</Text>
                </Flex>
              ))
              }
            </Box>
          }
        </Flex>
        {Object.keys(data.location.schedule).length > 0
          && <AssignedCleanersSection schedule={data.location.schedule} users={data.users} />
        }
      </SimpleGrid>
    </Box>
  )
}

export default BuildingInfo;