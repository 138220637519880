import { useEffect } from "react";
import appStoreBadge from '../../assets/app_store_badge.svg';
import googlePlayBadge from '../../assets/google_play_badge.png';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import {
  Flex
} from '@chakra-ui/react';

const AppStoreRedirect = () => {
  useEffect(() => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = 'https://apps.apple.com/us/app/brightgo/id6446709154';
      } else if (isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.brightgoproject';
      }
    }}, []);

  return (
  <Flex alignItems="center">
    <a href="https://apps.apple.com/us/app/brightgo/id6446709154">
      <img
        src={appStoreBadge}
        alt="Download on the App Store"
        style={{ width: 200, height: 'auto', padding:21 }}
      />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.brightgoproject">
      <img
        src={googlePlayBadge}
        alt="Get it on Google Play"
        style={{ width: 200, height: 'auto' }}
      />
    </a>
  </Flex>);
  };

export default AppStoreRedirect;
