import {
  Spinner,
  Box,
} from '@chakra-ui/react';

const FullScreenSpinner = () => {
  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Spinner size='xl' color="purple.500" />
    </Box>
  );
};

export default FullScreenSpinner;
