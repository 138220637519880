import DatePicker from 'react-datepicker';
import "./datepicker-styles.css";
import styles from "./styles.module.scss";
import { useTimezone } from '@/contexts/timezone_context';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { Box } from '@chakra-ui/react';

interface IDateTimePickerProps {
  value: any;
  onChange: (date: Date) => void;
  isNull?: boolean;
  showTime?: boolean;
  showDate?: boolean;
  popperPlacement?: any;
  disabled?: boolean;
  alignment?: "left" | "center" | "right";
  date?: Date;
  customDateFormat?: string;
  customBorderColor?: string;
  height?: number;
}

const DateTimePicker = (props: IDateTimePickerProps) => {
  const timezoneContext = useTimezone();
  const { value, onChange, showTime=true, showDate=true, popperPlacement="bottom", disabled=false, alignment="center", date, customDateFormat, customBorderColor, isNull=false, height=36 } = props;

  let dateFormat = "MM/dd/yyyy h:mm aa";

  if (customDateFormat) {
    dateFormat = customDateFormat;
  } else if (showDate && !showTime) {
    dateFormat = "MM/dd/yyyy";
  } else if (!showDate && showTime) {
    dateFormat = "h:mm aa";
  } else if (!showDate && !showTime) {
    throw new Error("DateTimePicker must show either date or time");
  }

  if (!showDate && !date) {
    throw new Error("DateTimePicker must have a date prop when not showing date, to avoid date being set as 2000/01/01");
  }

  // https://github.com/Hacker0x01/react-datepicker/issues/1787
  const handleChange = (selectedDate: Date) => {
    const zonedDate = timezoneContext.fromZonedTime(selectedDate);
    onChange(zonedDate);
  };

  const zonedValue = value ? timezoneContext.toZonedTime(value) : null;

  return (
    <Box
      sx={{
        '.react-datepicker__input-container input': {
          border: `1px solid ${isNull ? 'var(--chakra-colors-secondary-red)' : (customBorderColor || 'var(--chakra-colors-chakra-border-color)')}`,
          height: `${height}px`
        },
      }}
    >
      <DatePicker
        selected={zonedValue}
        disabled={disabled}
        onChange={handleChange}
        showPopperArrow={false}
        disabledKeyboardNavigation
        showTimeSelect={showTime}
        autoComplete="off"
        minDate ={!showDate ? date : undefined}
        maxDate ={!showDate ? date : undefined}
        showTimeSelectOnly={showTime && !showDate}
        dateFormat={isNull ? "-" : dateFormat}
        wrapperClassName={[styles.wrapper, disabled ? styles.disabled : "", styles[alignment]].join(" ")}
        popperPlacement={popperPlacement}
        // showIcon
        // icon={<HiOutlineExclamationCircle color="#6362F8" size={16} style={{ marginLeft: "4px", height: "36px !important" }} />}
      />
    </Box>
  );
};

export default DateTimePicker;
