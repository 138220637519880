import { startOfDay } from "date-fns";

export interface IIssue {
  id: number;
  title: string;
  currentStage: IssueStage;
  locationId?: number;
  assigneeId?: number;
  location?: { id: number, name: string };
  assignee?: { id: number, name: string };
  creator: { id: number, name: string };
  notesForCleaner?: string;
  attachments?: Array<{ url: string, filename: string }>;
  fixComment?: IIssueComment;
  dueDate: Date;
  createdAt: Date;
}

export interface IIssueComment {
  id?: number;
  text?: string;
  attachments?: Array<string>;
  creator?: { id: number, name: string };
  createdAt?: Date;
  isFixComment?: boolean;
}

export interface IIssueVersionChange {
  column: string;
  oldValue?: string | number;
  newValue?: string | number;
  creator?: { id: number, name: string };
  createdAt?: Date;
}

export interface IIssueActivityItem {
  activityType: IssueActivityType;
  activityObject: IIssueComment | IIssueVersionChange
}

export interface ICreateIssueCommentParams {
  text: string;
  attachments?: Array<any>;
}

export interface IUpdateIssueParams {
  assigneeId?: number;
  currentStage?: IssueStage;
  title?: string;
  locationId?: number;
  notesForCleaner?: string;
  attachments?: Array<{ url: string, filename: string }>;
  attachmentsToUpload?: Array<any>;
  dueDate?: Date;
}

export interface ICreateIssueParams {
  title: string;
  locationId: number;
  assigneeId?: number;
  assignee?: { id: number, name: string };
  notesForCleaner?: string;
  attachments?: Array<{ url: string, filename: string }>;
  attachmentsToUpload?: Array<any>;
  dueDate: Date;
}

export enum IssueStage {
  OPEN = "open",
  ASSIGNED_TO_FIELD = "assigned_to_field",
  FIXED_IN_FIELD = "fixed_in_field",
  CLOSED="closed",
}

export enum IssueActivityType {
  COMMENT = "comment",
  VERSION_CHANGE = "version_change",
}

export const StageTagMappings = {
  open: { textColor: "#6362F8", backgroundColor: "rgba(99, 98, 248, 0.13)", label: "Open" },
  assigned_to_field: { textColor: "#F99D5A", backgroundColor: "#FFEFD7", label: "Assigned to Field" }, 
  fixed_in_field: { textColor: "#4FC980", backgroundColor: "#D2F9E4", label: "Fixed in Field" }, 
  closed: { textColor: "#9596A0", backgroundColor: "rgba(149, 150, 160, 0.17)", label: "Closed" }, 
}

export interface IIssueFilters {
  locationId?: number;
  stages?: Array<IssueStage>;
  assigneeId?: number;
  issueIds?: Array<number>;
}

export const filterIssues = (issues: Array<IIssue>, filters: IIssueFilters) => {
  let filteredIssues = issues;

  if (filters.issueIds && filters.issueIds.length > 0) {
    filteredIssues = filteredIssues.filter((issue: IIssue) => filters.issueIds?.includes(issue.id));
  }

  if (filters.locationId) {
    filteredIssues = filteredIssues.filter((issue: IIssue) => issue.location.id === filters.locationId);
  }

  if (filters.assigneeId) {
    filteredIssues = filteredIssues.filter((issue: IIssue) => issue.assignee?.id === filters.assigneeId);
  }

  if (filters.stages && filters.stages.length > 0) {
    filteredIssues = filteredIssues.filter((issue: IIssue) => filters.stages?.includes(issue.currentStage));
  }

  return filteredIssues;
}

export const convertIssueFromServer = (issueFromServer: any, locations: Array<any>, users: Array<any>): IIssue => {
  const assignee = users[issueFromServer.assigneeId]
  const creator = users[issueFromServer.creatorId]
  const location = locations[issueFromServer.locationId]

  // https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
  const dt = new Date(issueFromServer.dueDate);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  return {
   ...issueFromServer,
    assignee: assignee ? { id: assignee.id, name: assignee.name } : null,
    creator: creator ? { id: creator.id, name: creator.name } : null,
    dueDate: dtDateOnly,
    createdAt: new Date(issueFromServer.createdAt),
    location: location ? { id: location.id, name: location.name } : null,
  };
}

export const convertActivityItemFromServer = (activityFromServer: any, users: Array<any>): IIssueActivityItem => {
  const creator = activityFromServer.activityObject.creatorId ? users[activityFromServer.activityObject.creatorId] : null;
  let newValue = activityFromServer.activityType === IssueActivityType.VERSION_CHANGE ? activityFromServer.activityObject.newValue : null;
  if (activityFromServer.activityObject.column === "assignee_id" || activityFromServer.activityObject.column === "created_by") {
    newValue = users[newValue]
  }

  return {
    ...activityFromServer,
    activityObject: {
      ...activityFromServer.activityObject,
      creator: creator ? { id: creator.id, name: creator.name } : null,
      createdAt: activityFromServer.activityObject.createdAt ? new Date(activityFromServer.activityObject.createdAt) : null,
      newValue: newValue,
    }
  };
}
