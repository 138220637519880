import { Box, Text } from '@chakra-ui/react';


const InspectionScore = ( {score, circleSize, textSize}) => {
  let displayText = 'N/A'
  let color = '#BDBDBD';
  if (score !== null && score !== undefined) {
    const hundredBasedScore = Math.round(score * 100);
    color = '#4FC980';
    if (hundredBasedScore < 80) {
      color = '#F6BF26';
    }
    if (hundredBasedScore < 65) {
      color = '#EF6666';
    }
    displayText = hundredBasedScore.toString();
  }
  if (displayText.length >= 3) {
    textSize = textSize - 2;
  }
  return (
    <Box
      width={circleSize + 'px'}
      height={circleSize + 'px'}
      borderRadius="full"
      backgroundColor={color}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize={textSize + 'px'} fontWeight={'bold'} color={'white'}>{displayText}</Text>
    </Box>
  );
};

export default InspectionScore;
