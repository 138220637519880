import useSWR from "swr";

import ReactECharts from "echarts-for-react";
import { Box, Flex, Text, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";
import { camelCaseToTitleCase } from "@/utils/formatting";

import ChartLoadingState from "./loading_state";

const IssuesPieChart = ({ issueCounts, title, colors }) => {
  const theme = useTheme()
  const data = Object.entries(issueCounts).map(
    ([key, value]) => {
      return {
        value: value,
        name: camelCaseToTitleCase(key),
        itemStyle: {
          color: colors[key],
        },
      }
    })

  let option = {
    textStyle: {
      fontFamily: "DM Sans"
    },
    legend: {
      orient: "vertical",
      right: "0%",
      top: "20%",
      padding: 12,

      backgroundColor: "white",
      borderRadius: 6,

      selectedMode: false,
      formatter: (name) => {
        var value = data.filter(item => item.name === name)[0].value;
        return (
          `${name} - ${value}`
        )
      },

      itemHeight: 16,
      itemWidth: 16,

      textStyle: {
        fontSize: 16,
        fontWeight: "500",
        width: "100",
        overflow: "break",
      },
    },
    tooltip: {
      show: false,
    },
    series: [
      {
        type: "pie",
        center: ["30%", "40%"],
        radius: ["40%", "70%"],
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        cursor: "auto",
        emphasis: {
          disabled: true,
        },
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2
        },
        data: data
      }
    ]
  }

  return (
    <Box
      borderRadius={"3xl"}
      backgroundColor={"white"}
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.5em"}
      height={"20em"}
      maxHeight={"25em"}
      width={"49%"}
    >
      <Text fontSize={"lg"} fontWeight={"medium"} marginBottom={"0.5em"}>{title}</Text>
      <ReactECharts option={option} style={{ height: "100%" }} />
    </Box>
  )
}

const IssueTrackingCharts = ({ locationId }) => {
  const authContext = useAuth();
  const theme = useTheme();

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/locations/${locationId}/metrics/issues`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return (
      <Flex flexDirection={"row"} justifyContent={"space-between"} minHeight={"20em"}>
        <ChartLoadingState
          spinnerSize={"4em"}
          backgroundColor={"white"}
          border={`1px solid ${theme.colors.gray[200]}`}
          height={"100%"}
          width={"49%"}
        />
        <ChartLoadingState
          spinnerSize={"4em"}
          backgroundColor={"white"}
          border={`1px solid ${theme.colors.gray[200]}`}
          height={"100%"}
          width={"49%"}
        />
      </Flex>
    )
  }


  return (
    <Flex flexDirection={"row"} justifyContent={"space-between"} minHeight={"20em"}>
      <IssuesPieChart
        issueCounts={data.dataSeries.historical}
        title={"Completed Issues"}
        colors={{
          "finishedLate": theme.colors.secondary.orange,
          "finishedOnTime": theme.colors.secondary.green,
        }}
      />
      <IssuesPieChart
        issueCounts={data.dataSeries.current}
        title={"Outstanding Issues"}
        colors={{
          "overdue": theme.colors.secondary.orange,
          "open": theme.colors.secondary.yellow,
        }}
      />
    </Flex>
  )
}

export default IssueTrackingCharts;
