import "./stream-styles.scss";
import styles from "./styles.module.scss";

import { Flex, Heading, Icon, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { BiSearch, BiX } from 'react-icons/bi';
import {
  Channel,
  ChannelHeader,
  ChannelList,
  Chat,
  InfiniteScroll,
  MessageInput,
  MessageList,
  Window,
} from "stream-chat-react";
import { useEffect, useState } from "react";

import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import { useAuth } from "@/contexts/auth_context";
import { StreamChat } from "stream-chat";
import { CenteredDateSeparator } from "@/components/Messaging/date_separator";
import { CustomChannelPreviewMessenger } from "@/components/Messaging/channel_preview";
import UserList from "@/components/Messaging/user_list";

const Messaging = () => {
  const authContext = useAuth();
  const streamConfig = authContext.state?.streamConfig
  const userInfo = authContext.state?.userToken?.user_info;
  // const organization = authContext.state?.userToken?.organization

  const [client, setClient] = useState(null);
  const [isSearchResultShown, setIsSearchResultShown] = useState(false);
  const [searchString, setSearchString] = useState("");
  const options = { state: true, limit: 30 };

  const initialFilters = {
    type: "messaging",
    members: { $in: [userInfo?.id?.toString()] },
    disabled: false,
    frozen: false,
  }
  const initialSort = { last_message_at: -1 }
  const [filters, setFilters] = useState(initialFilters);
  const [sort, setSort] = useState(initialSort);

  useEffect(() => {
    if (!streamConfig || !userInfo) { return }

    const newClient = new StreamChat(streamConfig.apiKey);
    const handleConnectionChange = ({ online = false }) => {
      if (!online) return console.log("Connection Lost");
      setClient(newClient);
    };

    newClient.on("connection.changed", handleConnectionChange);

    setFilters(initialFilters)
    newClient.connectUser(
      {
        id: userInfo.id.toString(),
        name: userInfo.name,
        language: "en",
      },
      streamConfig.token,
    );

    return () => {
      newClient.off("connection.changed", handleConnectionChange);
      newClient.disconnectUser().then(() => console.log("Connection Closed"));
    }

  }, [userInfo, streamConfig]);

  const startSearch = () => {
    const query = searchString.trim()
    if (query.length < 1) { return }

    setFilters({
      ...initialFilters,
      $or: [
        { "member.user.name": { "$autocomplete": query } },
        { "name": { "$autocomplete": query } }
      ]
    })
    setSort([{ member_count: 1 }, { last_message_at: -1 }]);
    setIsSearchResultShown(true);
  }

  const endSearch = () => {
    setFilters(initialFilters);
    setSort(initialSort);
    setSearchString("");
    setIsSearchResultShown(false);
  }

  if (!client || !streamConfig || !userInfo) return (<FullScreenSpinner />);

  return (
    <Chat client={client}>
      <Flex height={"100%"}>
        <Flex className={styles.sidebar} direction="column" flex={"3"}>
          <Heading className={styles.messagingHeader} size="lg">Messaging</Heading>
          <InputGroup className={styles.searchContainer}>
            <Input
              className={styles.searchBar}
              onChange={(event) => { setSearchString(event.target.value) }}
              onKeyDown={(event) => {
                // Execute search when user presses enter
                if (event.key === "Enter") { startSearch() }
              }}
              onKeyUp={(event) => {
                // If user clear the search bar manually, end search and reset channel list
                if (!event.target.value) { endSearch() }
              }}
              placeholder="Search..."
              value={searchString}
              variant="filled"
            />
            {/* Toggle search state on click */}
            <InputRightElement cursor="pointer" onClick={() => isSearchResultShown ? endSearch() : startSearch()}>
              {!isSearchResultShown && <Icon as={BiSearch} boxSize={5} color={"primary.500"} />}
              {isSearchResultShown && <Icon as={BiX} boxSize={6} color={"primary.500"} />}
            </InputRightElement>
          </InputGroup>
          <ChannelList
            filters={filters}
            sort={sort}
            options={options}
            setActiveChannelOnMount={false}
            allowNewMessagesFromUnfilteredChannels={false}
            Preview={CustomChannelPreviewMessenger}
            Paginator={InfiniteScroll}
          />
        </Flex>
        <Channel DateSeparator={CenteredDateSeparator}>
          <Window>
            <Heading className={styles.channelHeader} >
              <ChannelHeader />
              <UserList />
            </Heading>
            <MessageList messageActions={["delete"]} />
            <MessageInput
              maxRows={10}
              grow={true}
            />
          </Window>
        </Channel>
      </Flex>
    </Chat >
  );
};

export default Messaging;
