import {MdOutlineNotListedLocation, MdOutlineWrongLocation, MdOutlineLocationOn} from "react-icons/md";
import {
  useTheme,
} from "@chakra-ui/react";


const LocationStatus = ({locationStatus}: {locationStatus: string}) => {
  const theme = useTheme();
  switch(locationStatus) {
    case "inside_building":
      return <MdOutlineLocationOn size={14} style={{marginLeft: 2, marginRight: 2}} color={theme.colors.blackAlpha["600"]} />;
    case "out_of_bound":
      return <MdOutlineWrongLocation size={14} style={{marginLeft: 2, marginRight: 2}} color={theme.colors.blackAlpha["600"]} />;
    default:
      return <MdOutlineNotListedLocation size={14} style={{marginLeft: 2, marginRight: 2}} color={theme.colors.blackAlpha["600"]} />;
  }
}

export default LocationStatus;
