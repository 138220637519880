import { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  Box,
  Flex,
  NumberInput,
  NumberInputField,
  useToast,
  Input,
  Text,
  Divider,
  ButtonGroup,
} from '@chakra-ui/react';
import { addSeconds, addDays, formatISO } from "date-fns";
import { DateTimePicker } from './../DateTimePicker';
import { useTimezone } from "@contexts/timezone_context";
import { formatDuration } from '@/utils/formatting';
import styles from "./styles.module.scss";
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { nextDayValue } from '@/types/shift';

const NO_SELECTION_OPTION = '';

const ShiftEditModal = ({ 
  isOpen, 
  onClose, 
  initialShift,
  onUpdateShift,
  calendarEvent,
  users,
  locations,
  usersByLocationId,
  onSave,
}) => {
  const timezoneContext = useTimezone();
  const toast = useToast();

  const isEdit = initialShift !== null && initialShift !== undefined;
  const originalShift = initialShift;
  const [updatedShift, setUpdatedShift] = useState(null);

  const modalTitle = isEdit ? "Edit shift" : "Create new shift";

  const initialPaidBreakValue = (shift) => {
    let initialValue = false;
    if (shift) {
      if (shift.paidBreak !== null) {
        initialValue = shift.paidBreak;
      } else if (shift?.shiftBreakRule?.paid !== null) {
        initialValue = shift?.shiftBreakRule?.paid;
      }
    }

    return initialValue;
  }

  const [paidBreak, setPaidBreak] = useState<boolean>(initialPaidBreakValue(originalShift));
  const [showBreakConfig, setShowBreakConfig] = useState(originalShift?.mealStartTimestamp || originalShift?.mealEndTimestamp);

  const hasChanges = () => {
    return (JSON.stringify(originalShift) !== JSON.stringify(updatedShift))
  }

  const getDuration = (startDatetime, endDatetime) => {
   if (!startDatetime || !endDatetime) return 0;

    // Using addDays instead of +86400 here to handle the edge case from DST.
    const actualEndTime = endDatetime?.getTime() < startDatetime?.getTime() ? addDays(endDatetime, 1) : endDatetime;
    return (actualEndTime.getTime() - startDatetime?.getTime()) / 1000
  }

  const getNextDayComponent = (startDatetime, endDatetime) => {
    const nextDay = nextDayValue(startDatetime, endDatetime);
    
    return (
      <Flex h={6} alignItems="center" justifyContent="flex-end" mr={"32px"} mt={1}>
      {!nextDay ? null :
        <>
          <HiOutlineExclamationCircle color="#6362F8" size={14}/>
          <Text alignSelf="center" textAlign={"center"} fontSize={12} ml={1}>
            { `${nextDay} day` }
          </Text>
        </>}
      </Flex>
    );
  }

  const getDurationText = (startDatetime, endDatetime) => {
    const duration = getDuration(startDatetime, endDatetime);
    return (
      <>
        <Box flex={1} borderBottom="1px solid #D9DEE0" ml={2} />
        {
          duration ? (
            <Text
              px={2}>
              {formatDuration(duration)}
            </Text>
          ) : null
        }
        <Box flex={1} borderBottom="1px solid #D9DEE0" mr={2} />
      </>
    );
  }

  const getValidationResult = () => {
    if (!updatedShift?.locationId) {
      return "Please fill in the building.";
    }
    if (!updatedShift?.supervisorId) {
      return "Please fill in the supervisor.";
    }
    if (!updatedShift?.employeeId) {
      return "Please fill in the cleaner.";
    }
    if (!updatedShift?.calendarStartDatetime) {
      return "Please fill in the scheduled start time.";
    }
    if (!updatedShift?.calendarEndDatetime) {
      return "Please fill in the scheduled end time.";
    }

    return null;
  }

  useEffect(() => {
    setUpdatedShift(initialShift);
    setPaidBreak(initialPaidBreakValue(initialShift));
    setShowBreakConfig(initialShift?.mealStartTimestamp || initialShift?.mealEndTimestamp)
  }, [initialShift])

  const validateInput = () => {
    toast.closeAll()
    const validationError = getValidationResult();
    if (validationError) {
      toast({
        description: validationError,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (initialShift) {
      setUpdatedShift(initialShift);
    }
  }, [initialShift]);

  const handleDateTimeChange = (field, value) => {
    setUpdatedShift((prevShift) => ({
      ...prevShift,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    if (validateInput()) {
      onUpdateShift(isEdit ? calendarEvent : null, { ...(updatedShift || {}), paidBreak: paidBreak}).then(() => {
        toast({
          title: "Shift updated.",
          description: "The shift details have been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onSave();
        setUpdatedShift(null);
      }).catch((error) => {
        toast({
          description: error.message,
          position: "bottom",
          status: "error",
          variant: "left-accent",
          duration: 5000,
          isClosable: true,
        });
      })
    }
  };

  const renderTopSection = () => {
    const location = locations[updatedShift?.locationId];
    return (
      <>
       <FormControl className={styles.fieldContainer}>
        <FormLabel className={styles.fieldLabel}>Building</FormLabel>
        { isEdit ? <Input readOnly variant="unstyled" className={styles.fieldValue} value={location?.name}/> : (
        <Select
          size='md'
          placeholder='Select building'
          onChange={(event) => setUpdatedShift({...updatedShift, locationId: parseInt(event.target.value)})}
          value={updatedShift?.locationId || NO_SELECTION_OPTION}>
          {
            Object.values(locations).sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })).map((location) => {
              return <option key={location.id} value={location.id}>{location.name}</option>;
            })
          }
        </Select>)}
      </FormControl>
      <Flex className={styles.fieldContainer}>
        <FormLabel className={styles.fieldLabel}>Address</FormLabel>
        <Text className={styles.fieldValue}>{location?.address || "N/A"}</Text>
      </Flex>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"} isCentered>
      <ModalOverlay/>
      <ModalContent maxH="90vh">
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY="visible"
        >
          {renderTopSection()}
          <Divider my={6}/>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Supervisor</FormLabel>
              <Select
                size='md'
                placeholder='Select supervisor'
                onChange={(event) => setUpdatedShift({...updatedShift, supervisorId: parseInt(event.target.value)})}
                value={updatedShift?.supervisorId || NO_SELECTION_OPTION}>
                {
                  Object.values(users)
                    .filter((user) => user.isSupervisor)
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
                    .map((employee) => {
                      return <option key={employee.id} value={employee.id}>{employee.name}</option>;
                    })
                }
              </Select>
            </FormControl>
            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Cleaner</FormLabel>
              <Flex alignItems={"center"}>
                <Select
                  size='md'
                  placeholder='Select cleaner'
                  onChange={(event) => setUpdatedShift({...updatedShift, employeeId: parseInt(event.target.value)})}
                  value={updatedShift?.employeeId || NO_SELECTION_OPTION}>
                  {
                    Object.values(users).sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })).map((employee) => {
                      return <option key={employee.id} value={employee.id}>
                        {employee.name}
                        </option>;
                    })
                  }
                </Select>
              </Flex>
            </Flex>

            <Divider my={6}/>

          {/* Scheduled Time */}
          <FormControl mb={4}>
            <FormLabel>Scheduled Time</FormLabel>
            <Flex justifyContent="space-between" alignItems="center">
              <Box width={200}>
                <DateTimePicker
                  value={updatedShift?.calendarStartDatetime}
                  onChange={(value) => handleDateTimeChange('calendarStartDatetime', value)}
                  disabled={isEdit}
                />
              </Box>
              { getDurationText(updatedShift?.calendarStartDatetime, updatedShift?.calendarEndDatetime) }
              <Box width={200}>
                <DateTimePicker
                  value={updatedShift?.calendarEndDatetime}
                  onChange={(value) => handleDateTimeChange('calendarEndDatetime', value)}
                  ml={2}
                  disabled={isEdit}
                />
              </Box>
            </Flex>
            { getNextDayComponent(updatedShift?.calendarStartDatetime, updatedShift?.calendarEndDatetime) }
          </FormControl>

          {/* Clock-In/Out */}
          <FormControl mb={4}>
            <FormLabel>Clock-In/Out</FormLabel>
            <Flex justifyContent="space-between" alignItems="center">
              <Box width={200}>
                <DateTimePicker
                  value={updatedShift?.clockInTimestamp}
                  onChange={(value) => handleDateTimeChange('clockInTimestamp', value)}
                />
              </Box>
              { getDurationText(updatedShift?.clockInTimestamp, updatedShift?.clockOutTimestamp) }
              <Box width={200}>
                <DateTimePicker
                  value={updatedShift?.clockOutTimestamp}
                  onChange={(value) => handleDateTimeChange('clockOutTimestamp', value)}
                  ml={2}
                />
              </Box>
            </Flex>
            { getNextDayComponent(updatedShift?.clockInTimestamp, updatedShift?.clockOutTimestamp) }

          </FormControl>

          <Divider my={6}/>

          {/* Break Rules */}
          <FormControl mb={4}>
            <FormLabel>Break</FormLabel>
            <Flex>
              {updatedShift?.shiftBreakRule && updatedShift?.shiftBreakRule.breakDurationSeconds && updatedShift?.shiftBreakRule.shiftDurationSeconds ? (
                <Flex alignItems="center">
                  <Text fontWeight="bold" mr={1}>
                    {updatedShift?.shiftBreakRule.breakDurationSeconds / 60}m
                  </Text>
                  <Text mr={1}>
                    {updatedShift?.shiftBreakRule.paid ? "paid" : "unpaid"}
                  </Text>
                  <Text mr={1}>break after</Text>
                  <Text fontWeight="bold" mr={1}>
                    {updatedShift?.shiftBreakRule.shiftDurationSeconds / 3600}h 00m
                  </Text>
                  <Text>working</Text>
                </Flex>
              ) : (
                <Text fontWeight="bold">N/A</Text>
              )}
            </Flex>

            {/* Add break button */}
            {!showBreakConfig && (
              <Button
                mt={2}
                colorScheme="blue"
                variant="link"
                onClick={() => setShowBreakConfig(true)}
              >
                + Add break
              </Button>
            )}

            {/* Break configuration section */}
            { showBreakConfig && (
              <>
                <Flex justifyContent="space-between" alignItems="center" mt="10px">
                  <Box width={200}>
                    <DateTimePicker
                      value={updatedShift?.mealStartTimestamp}
                      onChange={(value) => handleDateTimeChange('mealStartTimestamp', value)}
                    />
                  </Box>
                  { getDurationText(updatedShift?.mealStartTimestamp, updatedShift?.mealEndTimestamp) }
                  <Box width={200}>
                    <DateTimePicker
                      value={updatedShift?.mealEndTimestamp}
                      onChange={(value) => handleDateTimeChange('mealEndTimestamp', value)}
                      ml={2}
                    />
                  </Box>
                </Flex>
                { getNextDayComponent(updatedShift?.mealStartTimestamp, updatedShift?.mealEndTimestamp) }

                <Checkbox
                  isChecked={paidBreak}
                  onChange={() => setPaidBreak(!paidBreak)}
                  style={{ marginRight: '5px' }}
                >
                  Paid Break
                </Checkbox>
              </>
            )}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="solid" onClick={handleSubmit} mr={4}>
            { isEdit ? "Update Shift" : "Create Shift" }
          </Button>
          <Button variant="ghost" onClick={() => {
            onClose();
            setUpdatedShift(null);
          }}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShiftEditModal;
