import { ErrorView } from '../ErrorView';
import { Flex } from '@chakra-ui/react';

const ErrorPage = ({ error, errorMessage }: { error?: any; errorMessage?: string }) => {
  const message = error?.statusText || error?.message || errorMessage;
  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <ErrorView message={message} />
    </Flex>
  );
};

export default ErrorPage;
