import styles from "./styles.module.scss";

interface ITagProps {
  text: string;
  textColor: string;
  backgroundColor: string | any;
}

const IssueTag = (props: ITagProps) => {
  const { text, textColor, backgroundColor } = props;

  return (
    <div className={styles.container} style={{ backgroundColor: backgroundColor, color: textColor }}>
      { text }
    </div>
  );
};

export default IssueTag;
