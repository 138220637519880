import useSWR from "swr";

import { useAuth } from "@/contexts/auth_context";
import { Heading, Spinner } from "@chakra-ui/react";

import AttendanceTaskListItem from "./attendance_task_list_item";
import { AttendanceTaskMissing } from "../AttendanceTaskMissing";

import styles from "./styles.module.scss";

const AttendanceTaskLoadingState = ({ }) => (
  <div className={styles.mainContainer}>
    <div className={styles.loadingState}>
      <Spinner size='xl' color="purple.500" />
    </div>
  </div>
)

const AttendanceTaskList = ({ }) => {
  const authContext = useAuth();
  const userInfo = authContext.state.userToken.user_info;

  const { data, error, isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/attendance_tasks?assignee_id=${userInfo.id}&completed=false`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => response.json())
    }
  );

  const tasks = data?.tasks

  if (isLoading) {
    return <AttendanceTaskLoadingState />
  }

  if (error) {
    return <AttendanceTaskMissing error={true} />
  }

  if (!tasks) {
    return
  }

  return (
    <div>
      <div className={styles.mainContainer}>
        <Heading className={styles.header} size="md">My Open Tasks</Heading>
        <div className={styles.listContainer}>
          {Object.values(data?.tasks).map(task => {
            return <AttendanceTaskListItem
              key={task.id}
              task={task}
            />
          })}
        </div>
      </div>
    </div>
  );
}

export default AttendanceTaskList;
