const calculateBudgetPercentage = (actualHours: number | null, budgetHours: number | null) => {
  if (actualHours === null || budgetHours === null) {
    return null;
  }
  return budgetHours === 0 ? 0 : (actualHours - budgetHours) / budgetHours * 100;
};

const formatBudgetActualPercentageText = (budgetPercentage: number | null) => {
  return `${budgetPercentage !== null ? `${toFixedWithoutZero(Math.abs(budgetPercentage))}%` : '--'}`;
};

const formatBudgetActualOverUnderText = (budgetPercentage: number | null) => {
  return budgetPercentage !== null ? (budgetPercentage > 0 ? 'Over' : 'Under') + ' budget' : 'Budget vs. Actual';
};

const formatBudgetActualHourText = (actualHours: number | null, budgetHours: number | null) => {
  return `${actualHours !== null ? `${toFixedWithoutZero(actualHours)}` : '--'} actual hrs / ${budgetHours !== null ? `${toFixedWithoutZero(budgetHours)}` : '--'} budget hrs`;
};

const toFixedWithoutZero = (hours: number) => {
  if (hours === null) {
    return '--';
  }
  const fixed = hours.toFixed(1);
  return fixed.endsWith('.0') ? fixed.slice(0, -2) : fixed;
};


export { calculateBudgetPercentage, formatBudgetActualPercentageText, formatBudgetActualHourText, formatBudgetActualOverUnderText, toFixedWithoutZero };
