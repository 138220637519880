import { Box, Flex, Skeleton, Image, Button } from "@chakra-ui/react";
import styles from "./styles.module.scss";
import { useState } from "react";

import deleteIcon from '../../assets/delete.png';
import addPhotoButton from '../../assets/add-photo-button.png';

export interface IPhoto {
  src: string;
  width: number;
  height: number;
  photoIndex: number;
}
interface IPhotoListProps {
  urls: Array<string>;
  onRemove?: (photoToRemove: string, index: number) => void;
  photoSelectorTrigger?: () => void;
  canEdit?:boolean;
  onClickPhoto?: (photos: Array<IPhoto>, photo: IPhoto) => void;
  size?: number;
}

const PhotoList = (props: IPhotoListProps) => {
  const { urls, onClickPhoto, onRemove, photoSelectorTrigger, canEdit, size=96 } = props;

  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const photos = urls.map((photoUrl: string, index: number) => {
    return { src: photoUrl, width: 960, height: 960, photoIndex: index };
  });

  const ImageWithLoading = ( { photo, index, onClick } ): JSX.Element => {
    return (
      <Box
        position="relative" width={`${size}px`} height={`${size}px`}
        mt={2}
        mr={canEdit ? 3 : 2}
      >
        {!imageLoaded && (
          <Skeleton startColor="gray.100" endColor="gray.300" height={`${size}px`} width={`${size}px`} borderRadius="xl" />
        )}
        <Image
          loading='lazy'
          src={photo}
          borderRadius="xl"
          boxSize={`${size}px`}
          objectFit="cover"
          style={{ position: 'absolute', top: 0, left: 0 }}
          onLoad={() => setImageLoaded(true)}
          onClick={onClick}
          cursor={'pointer'}
        />
        { canEdit && onRemove ? (
          <Button variant="unstyled" height="24px" width="24px" right={`${-(size - 16)}px`} className={styles.photoDeleteButtonContainer} onClick={() => {
            onRemove(photo, index);
          }}>
            <Image src={deleteIcon} className={styles.photoDeleteButton}/>
          </Button>
        ) : null }
      </Box>
    );
  };

  if (!canEdit && photos.length === 0) {
    return null;
  }

  return (
    <div className="my-photo-album">
      <Flex flexWrap="wrap" mt={2}>
        {
          photos.map((photo: IPhoto) => {
            return (
              <ImageWithLoading
                key={photo.photoIndex}
                photo={photo.src}
                index={photo.photoIndex}
                onClick={() => onClickPhoto ? onClickPhoto(photos, photo) : {}}
              />
            );
          })
        }

        {
          canEdit && photoSelectorTrigger ? (
            <Box
              position="relative" width={`${size}px`} height={`${size}px`}
              mt={2}
              mr={2}
              onClick={() => photoSelectorTrigger()}
              style={{ cursor: 'pointer' }}
            >
              <Image src={addPhotoButton} />
            </Box>
          ) : null
        }
      </Flex>
    </div>
  );
};

export default PhotoList;
