import { useAuth } from "@contexts/auth_context";
import { Heading, Text, Flex, Table, Thead, Tbody, Tr, Th, Td, useTheme, Button, Box, Select } from "@chakra-ui/react";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import {ErrorPage} from "@/components/ErrorPage";
import useSWR from "swr";
import { EventFilter } from "@/components/EventFilter";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { InspectionScore } from "@/components/InspectionScore";
import { useTimezone } from "@/contexts/timezone_context";

const InspectionDashboard: React.FC = () => {
  const authContext = useAuth();
  const theme = useTheme();
  const timezoneContext = useTimezone();

  // Filtering
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [buildingFilteredInspectionIds, setBuildingFilteredInspectionIds] = useState<Set<string>>(new Set());
  const [supervisorFilteredInspectionIds, setSupervisorFilteredInspectionIds] = useState<Set<string>>(new Set());
  const [dateFilteredInspectionIds, setDateFilteredInspectionIds] = useState<Set<string>>(new Set());
  const [isBuildingFilterLoading, setIsBuildingFilterLoading] = useState<boolean>(true);
  const [isSupervisorFilterLoading, setIsSupervisorFilterLoading] = useState<boolean>(true);
  const [isDateFilterLoading, setIsDateFilterLoading ] = useState<boolean>(true);
  const [ searchParams, setSearchParams ] = useSearchParams();

  // Sorting
  const [sorting, setSorting] = useState<string>("inspection_date");
  const sortingOptions = {
    "inspection_date": {
      label: "Newest",
      sortFunction: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    },
    "highest_score": {
      label: "Highest score",
      sortFunction: (a, b) => b.score - a.score
    },
    "lowest_score": {
      label: "Lowest score",
      sortFunction: (a, b) => a.score - b.score
    },
    "weekly_hour": {
      label: "Weekly hour",
      sortFunction: (a, b) => locations[b.locationId].budgetedHoursPerWeek - locations[a.locationId].budgetedHoursPerWeek
    },
    "building_name": {
      label: "Building name",
      sortFunction: (a, b) => locations[a.locationId].name.localeCompare(locations[b.locationId].name)
    },
  }

  const inspectionIdSetsFromOtherFilters = (inspectionIdSetForCurrentFilter) => {
    return [buildingFilteredInspectionIds, supervisorFilteredInspectionIds, dateFilteredInspectionIds]
    .filter((inspectionIdSet) => {
      return inspectionIdSet != inspectionIdSetForCurrentFilter;
    });
  }

  const rerenderFilters = () => {
    // hide all inspections before rerendering to avoid flickering.
    setBuildingFilteredInspectionIds(new Set());
    setSupervisorFilteredInspectionIds(new Set());
    setDateFilteredInspectionIds(new Set());
    setResetKey(!resetKey);
  }

  const setFilterLoading = () => {
    setIsBuildingFilterLoading(true);
    setIsSupervisorFilterLoading(true);
    setIsDateFilterLoading(true);
  }

  const { data, error, isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/inspections`,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then(response => response.json())
    },
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    // useEffect has to be above any early returns
    // https://github.com/vercel/swr/discussions/1815
    if (data && data.inspections) {
      // This applies the filters to the just loaded inspections.
      // This will also eventually set the filters to be loaded.
      rerenderFilters();
    } else {
      // Set filters to be loading when data is reset to null.
      setFilterLoading();
    }
  }, [data]);

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error}/>;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error}/>;
  }

  const onRowClick = (inspectionId) => {
    window.open(`/inspection/${inspectionId}`, '_blank');
  };

  const { users, inspections, locations } = data;
  const allInspections = inspections.filter(inspection => inspection.status === 'completed');
  const filteredInspections = allInspections.filter((inspection) => {
    return buildingFilteredInspectionIds.has(inspection.id)
    && supervisorFilteredInspectionIds.has(inspection.id)
    && dateFilteredInspectionIds.has(inspection.id);
  }).sort ((a, b) => {
    return sortingOptions[sorting].sortFunction(a, b);
  });

  return (
    <Flex
      flexDirection={"column"}
      height="100%"
    >
      <Heading
        as="h1"
        size="lg"
        marginLeft={6}
        paddingTop={6}
        marginBottom={0}
      >
        <Text>Inspections</Text>
      </Heading>
      <Box
        borderRadius="3xl"
        flex={1}
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100%"
      >
        <Flex
          justifyContent={"space-between"}
          mx={6}
          mt={6}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text>Filter by:</Text>
            <Flex
              alignItems={"center"}
            >
              <Box mr={6}>
                <EventFilter
                  key={"BuildingFilter" + resetKey}
                  isLoading={isBuildingFilterLoading}
                  events={allInspections}
                  eventIdSetsFromOtherFilters={inspectionIdSetsFromOtherFilters(buildingFilteredInspectionIds)}
                  getFilterKey={(inspection) => locations[inspection.locationId]?.name}
                  getEventId={(inspection) => inspection.id}
                  convertFilterKeyToLabel={(filterKey) => filterKey}
                  updateEvent={(inspectionIds) => {
                    setBuildingFilteredInspectionIds(inspectionIds);
                    setIsBuildingFilterLoading(false);
                  }}
                  filterName={"Building"}
                />
              </Box>
              <Box mr={6}>
                <EventFilter
                  key={"SupervisorFilter" + resetKey}
                  isLoading={isSupervisorFilterLoading}
                  events={allInspections}
                  eventIdSetsFromOtherFilters={inspectionIdSetsFromOtherFilters(supervisorFilteredInspectionIds)}
                  getFilterKey={(inspection) => users[inspection.userId]?.name}
                  getEventId={(inspection) => inspection.id}
                  convertFilterKeyToLabel={(filterKey) => filterKey}
                  updateEvent={(inspectionIds) => {
                    setSupervisorFilteredInspectionIds(inspectionIds);
                    setIsSupervisorFilterLoading(false);
                  }}
                  filterName={"Inspector"}
                />
              </Box>
              <Box mr={6}>
                <EventFilter
                  key={"DateFilter" + resetKey}
                  isLoading={isDateFilterLoading}
                  events={allInspections}
                  eventIdSetsFromOtherFilters={inspectionIdSetsFromOtherFilters(dateFilteredInspectionIds)}
                  getFilterKey={(inspection) => {
                    // convert to a string like "2024 Sep"
                    return timezoneContext.formatDate(inspection.createdAt, "MMM yyyy");
                  }}
                  getEventId={(inspection) => inspection.id}
                  convertFilterKeyToLabel={(filterKey) => filterKey}
                  updateEvent={(inspectionIds) => {
                    setDateFilteredInspectionIds(inspectionIds);
                    setIsDateFilterLoading(false);
                  }}
                  filterName={"Date"}
                />
              </Box>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setFilterLoading();
                  setSearchParams('');
                  rerenderFilters();
                }}
              >
                Clear all
              </Button>
            </Flex>
          </Flex>
          <Flex
            alignItems={"flex-start"}
            flexDirection={"column"}
          >
            <Text fontSize={14} mr={4}>Sort by: </Text>
            <Select
              width={60}
              size={"sm"}
              py={1}
              defaultValue={sorting}
              borderRadius={4}
              iconSize={14}
              focusBorderColor="primary.500"
              onChange={(event) => setSorting(event.target.value)}
            >
              {Object.keys(sortingOptions).map((key) => (
                <option key={key} value={key}>{sortingOptions[key].label}</option>
              ))}
            </Select>
          </Flex>
        </Flex>
        <Flex
          my={6}
          flex={1}
          flexDirection="column"
          overflowY="hidden"
        >
          <Flex className={styles.tableHeaderContainer}>
            <Table variant="simple" colorScheme="gray">
              <Thead position="sticky" top={0} bg="white">
                <Tr>
                  <Th width="15%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={6} >Building</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Inspection date</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Score</Th>
                  <Th width="15%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Inspected by</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Weekly budget hours</Th>
                  <Th width="25%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Address</Th>
                </Tr>
              </Thead>
            </Table>
          </Flex>
          <Flex
            overflowY="scroll"
          >
            <Table variant="simple" colorScheme="gray">
              <Tbody>
                {filteredInspections.map((inspection) => (
                  <Tr width="100%"
                    key={inspection.id}
                    onClick={() => onRowClick(inspection.id)} style={{ cursor: 'pointer' }}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Td width="15%" pl={6} >{locations[inspection.locationId].name}</Td>
                    <Td width="10%" pl={0} >{timezoneContext.formatDate(inspection.createdAt, "MMM d, yyyy")}</Td>
                    <Td width="10%" pl={0} ><InspectionScore circleSize={32} textSize={14} score={inspection.score}/></Td>
                    <Td width="15%" pl={0} >{users[inspection.userId]?.name}</Td>
                    <Td width="10%" pl={0} >{Math.round(locations[inspection.locationId].budgetedHoursPerWeek)}</Td>
                    <Td width="25%" pl={0} >{locations[inspection.locationId].address}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default InspectionDashboard;
