export const nextDayValue = (startDatetime, endDatetime) => {
  if (!startDatetime || !endDatetime) {
    return null;
  }

  // Check if endDatetime is on a different calendar day than startDatetime
  if (
    startDatetime.getFullYear() === endDatetime.getFullYear() &&
    startDatetime.getMonth() === endDatetime.getMonth() &&
    startDatetime.getDate() === endDatetime.getDate()
  ) {
    return null;
  }

  if (endDatetime < startDatetime) {
    return "previous";
  } else {
    return "next";
  }
}

export const convertShiftFromServer = (shiftFromServer: any, timezoneContext: any): any => {
  const mealStart = shiftFromServer.punches?.find(punch => punch.punchType === "meal_start" && !punch.discardedAt)?.punchInTimestamp;
  const mealEnd = shiftFromServer.punches?.find(punch => punch.punchType === "meal_end" && !punch.discardedAt)?.punchInTimestamp;
  return {
    ...shiftFromServer,
    calendarStartDatetime: shiftFromServer.calendarStartDatetime ? timezoneContext.parse(shiftFromServer.calendarStartDatetime) : null,
    calendarEndDatetime: shiftFromServer.calendarEndDatetime ? timezoneContext.parse(shiftFromServer.calendarEndDatetime) : null,
    clockInTimestamp: shiftFromServer.clockInTimestamp ? timezoneContext.parse(shiftFromServer.clockInTimestamp) : null,
    clockOutTimestamp: shiftFromServer.clockOutTimestamp ? timezoneContext.parse(shiftFromServer.clockOutTimestamp) : null,
    mealStartTimestamp: mealStart ? timezoneContext.parse(mealStart) : null,
    mealEndTimestamp: mealEnd ? timezoneContext.parse(mealEnd) : null,
  };
}
