import { useAuth } from "@contexts/auth_context";
import { Heading, Text, Flex, Table, Thead, Tbody, Tr, Th, Td, useTheme, Button, Box, Select, Icon, FormControl, FormLabel, Tooltip } from "@chakra-ui/react";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import { ErrorPage } from "@/components/ErrorPage";
import useSWR from "swr";
import styles from "./styles.module.scss";
import { domainUrl } from "@/utils/fetch_utils";
import { useTimezone } from "@contexts/timezone_context";
import { EventFilterTray } from "@/components/EventFilterTray";
import { useEffect, useMemo, useState } from "react";
import { DateTimePicker } from "@/components/DateTimePicker";
import PayPeriodSelect, { IPayPeriodOption, PayPeriodTypeMappings } from "@/components/PayPeriodSelect/pay_period_select";
import { convertAggregateItemFromServer } from "@/types/calendarEvent"

import { CSVLink } from "react-csv";
import { toFixedWithoutZero, formatBudgetActualPercentageText } from "@/utils/labor_hour_utils";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { EventFilter } from "@/components/EventFilter";

enum TimesheetGroup {
  LOCATION_ID = "location_id",
  EMPLOYEE_ID = "employee_id",
  EMPLOYEE_LOCATION_ID = "employee_location_id",
}

const TimesheetGroupMappings = {
  location_id: { label: "Building", apiInput: "location_id" },
  employee_id: { label: "Employee", apiInput: "employee_id" },
  employee_location_id: { label: "Employee, Building", apiInput: "employee_id,location_id" }, 
}

const PayRateTypeMappings = {
  hourly: "Hourly",
  monthly: "Monthly",
  salaried: "Salaried",
}

const COLUMNS = {
  assigned_user: { label: "Employee", width: "15%", key: "assignedUserName" },
  location: { label: "Building", width: "15%", key: "locationName" },
  pay_rate_type: { label: "Pay Rate Type", width: "10%", key: "payRateType" },
  actual_hours: { label: "Actual Hours", width: "10%", key: "actualHours" },
  scheduled_hours: { label: "Scheduled Hours", width: "10%", key: "scheduledHours" },
  actual_vs_scheduled_hours: { label: "Actual vs Scheduled", width: "10%", key: "actualVsScheduled" },
  ot_hours: { label: "OT Hours", width: "10%", key: "otHours" },
  total_shifts: { label: "Scheduled Shifts", width: "10%", key: "totalShifts" },
  missed_shifts: { label: "Missed Shifts", width: "10%", key: "missedShifts" },
}

const ActualVsBudgetHighlight = ({ value, actualHours, scheduledHours }) => {
  const theme = useTheme();
  if (value > 1.0 || value < 0.8) {
    return (
      <Box
        display="flex"
        alignItems="center"
        bg={theme.colors.secondary.red}
        color="white"
        px={2}
        py={0}
        borderRadius="md"
        width="fit-content"
        alignSelf="start"
      >
        {/* <Box color={theme.colors.secondary.red} bg="white" borderRadius="full" mr="8px">
          { value > 1.0 ? <BsArrowUpCircle size="24px" color={theme.colors.secondary.red} /> : <BsArrowDownCircle size="24px" /> }
        </Box> */}
        <Tooltip label={`(${actualHours} hours / ${scheduledHours} hours) * 100% = ${(value * 100).toFixed(0)}%`} fontSize="md" placement="top" shouldWrapChildren>
          <Text fontSize="24px" fontWeight="medium">{`${(value * 100).toFixed(0)}%`}</Text>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Text fontSize="24px" fontWeight="medium">{`${(value * 100).toFixed(0)}%`}</Text>
  )
}

const ActualVsBudgetHighlightInTable = ({ value, actualHours, scheduledHours }) => {
  const theme = useTheme();
  if (value > 1.0 || value < 0.8) {
    return (
      <Box
        display="flex"
        alignItems="center"
        bg={theme.colors.secondary.red}
        color="white"
        px={2}
        py={0}
        borderRadius="md"
        width="48px"
        alignSelf="start"
      >
        {/* <Box color={theme.colors.secondary.red} bg="white" borderRadius="full" mr="4px">
          { value > 1.0 ? <BsArrowUpCircle size="16px" color={theme.colors.secondary.red} /> : <BsArrowDownCircle size="14px" /> }
        </Box> */}
        <Tooltip label={`(${actualHours} hours / ${scheduledHours} hours) * 100% = ${(value * 100).toFixed(0)}%`} fontSize="md" placement="top" shouldWrapChildren>
          <Text fontSize="14px" fontWeight="medium">{`${(value * 100).toFixed(0)}%`}</Text>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Text fontSize="14px">{`${(value * 100).toFixed(0)}%`}</Text>
  )
}

const TimesheetDashboard: React.FC = () => {
  const authContext = useAuth();
  const theme = useTheme();
  const timezoneContext = useTimezone();

  const [results, setResults] = useState<Array<any>>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  // Filtering
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [buildingFilteredIds, setBuildingFilteredIds] = useState<Set<string>>(new Set());
  const [employeeFilteredIds, setEmployeeFilteredIds] = useState<Set<string>>(new Set());
  const [statusFilteredIds, setStatusFilteredIds] = useState<Set<string>>(new Set());
  const [isBuildingFilterLoading, setIsBuildingFilterLoading] = useState<boolean>(true);
  const [isEmployeeFilterLoading, setIsEmployeeFilterLoading] = useState<boolean>(true);
  const [isStatusFilterLoading, setIsStatusFilterLoading ] = useState<boolean>(true);

  const [groupBy, setGroupBy] = useState<TimesheetGroup>(TimesheetGroup.EMPLOYEE_ID);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<IPayPeriodOption | null>(null);

  const showBuildingColumn = groupBy !== TimesheetGroup.EMPLOYEE_ID;
  const showEmployeeColumn = groupBy !== TimesheetGroup.LOCATION_ID;

  const { data, error, isLoading } = useSWR(selectedPayPeriod ? `${domainUrl}/calendar_events/timesheet_summary?start_datetime=${encodeURIComponent(selectedPayPeriod?.startDate.toISOString())}&end_datetime=${encodeURIComponent(selectedPayPeriod?.endDate.toISOString())}&group=${TimesheetGroupMappings[groupBy].apiInput}` : null,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then((response) => response.json())
    }
  ) || { data: [], error: null, isLoading: true };

  const currentDate = timezoneContext.startOfDay(new Date());
  const [customStartDate, setCustomStartDate] = useState<Date>(new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000));
  const [customEndDate, setCustomEndDate] = useState<Date>(currentDate);

  useEffect(() => {
    const startDateParam = searchParams.get("custom_start_date");
    const endDateParam = searchParams.get("custom_end_date");
    if (startDateParam) setCustomStartDate(new Date(decodeURIComponent(startDateParam)));
    if (endDateParam) setCustomEndDate(new Date(decodeURIComponent(endDateParam)));
  }, []);

  const { data: payPeriodData, error: payPeriodError, isLoading: payPeriodIsLoading } = useSWR(
    `${domainUrl}/pay_periods`,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then((response) => response.json())
    }
  );
  
  const payPeriodOptions = useMemo(() => {
    let options = { "custom": { type: "custom", label: PayPeriodTypeMappings.custom.label, startDate: customStartDate, endDate: customEndDate } };

    payPeriodData?.payPeriods?.forEach((payPeriod) => {
      const dtStart = timezoneContext.parse(payPeriod.startDatetime);
      const dtEnd = timezoneContext.parse(payPeriod.endDatetime);

      options[payPeriod.payPeriodType] = { type: payPeriod.payPeriodType, label: PayPeriodTypeMappings[payPeriod.payPeriodType]?.label, startDate: dtStart, endDate: dtEnd }
    });
    return options;
  }, [payPeriodData]);

  useEffect(() => {
    const searchParamsForPayPeriod = searchParams.get("pay_period");
    if (searchParamsForPayPeriod) {
      setSelectedPayPeriod(payPeriodOptions[searchParamsForPayPeriod])
    }
  }, [payPeriodOptions]);

  const idSetsFromOtherFilters = (idSetForCurrentFilter: Set<number>) => {
    return [buildingFilteredIds, employeeFilteredIds, statusFilteredIds].filter((idSet) => {
      return idSet != idSetForCurrentFilter;
    });
  }

  const rerenderFilters = () => {
    // hide all issue before rerendering to avoid flickering.
    setBuildingFilteredIds(new Set());
    setEmployeeFilteredIds(new Set());
    setStatusFilteredIds(new Set());
    setResetKey(!resetKey);
  }

  const setFilterLoading = () => {
    setIsBuildingFilterLoading(true);
    setIsEmployeeFilterLoading(true);
    setIsStatusFilterLoading(true);
  }

  useEffect(() => {
    if (data) {
      setResults(data.results.map((item: any) => convertAggregateItemFromServer(item, data.locations, data.users)));
    } else {
      setResults([]);
    }
  }, [data]);

  const onRowClick = (result) => {
    let timesheetCardQueryParams: Array<string> = []

    // Pay Period Type
    if (selectedPayPeriod) {
      timesheetCardQueryParams.push(`pay_period=${selectedPayPeriod.type}`)

      // Custom Dates if needed
      if (selectedPayPeriod.type === "custom") {
        timesheetCardQueryParams.push(`custom_start_date=${encodeURIComponent(selectedPayPeriod.startDate.toISOString())}`)
        timesheetCardQueryParams.push(`custom_end_date=${encodeURIComponent(selectedPayPeriod.endDate.toISOString())}`)
      }
    }

    if (groupBy === TimesheetGroup.EMPLOYEE_ID || groupBy === TimesheetGroup.EMPLOYEE_LOCATION_ID) {
      timesheetCardQueryParams.push(`Employee=${encodeURIComponent(result.assignedUser?.name)}`)
    }
    if (groupBy === TimesheetGroup.LOCATION_ID || groupBy === TimesheetGroup.EMPLOYEE_LOCATION_ID) {
      timesheetCardQueryParams.push(`Building=${encodeURIComponent(result.location?.name)}`)
    }

    window.open(`/timesheet_card?${timesheetCardQueryParams.join("&")}`, '_blank');
  }

  const SelectGroupBy = (): JSX.Element => {
    return (
      <>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Group By</FormLabel>
          <Select
            size='md'
            //placeholder='Group by'
            onChange={(event) => setGroupBy(event.target.value as TimesheetGroup)}
            value={groupBy}>
            {
              Object.values(TimesheetGroup).map((stage) => {
                return <option key={stage} value={stage}>{TimesheetGroupMappings[stage].label}</option>;
              })
            }
          </Select>
        </FormControl>
      </>
    )
  }

  const filterData = (currentData: Array<any>) => {
    return currentData.filter((result: any) => {
      if (showBuildingColumn && !showEmployeeColumn) {
        return buildingFilteredIds.has(result.id) && statusFilteredIds.has(result.id);
      } else if (!showBuildingColumn && showEmployeeColumn) {
        return employeeFilteredIds.has(result.id) && statusFilteredIds.has(result.id);
      } else if (!showBuildingColumn && showEmployeeColumn) {
        statusFilteredIds.has(result.id)
      }

      return buildingFilteredIds.has(result.id) && employeeFilteredIds.has(result.id) && statusFilteredIds.has(result.id);
    })
  }
  const filteredData = filterData(Object.values(results)).sort((a, b) => a.assignedUser?.name.localeCompare(b.assignedUser?.name) || a.location?.name.localeCompare(b.location?.name));
  const topLevelTotals = { actualHours: filteredData.reduce((a,v) =>  a = a + v.actualHours, 0), scheduledHours: filteredData.reduce((a,v) =>  a = a + v.scheduledHours, 0), otHours: filteredData.reduce((a,v) =>  a = a + v.otHours, 0) };

  const csvData = (filteredData || []).map((result) => ({
    assignedUserName: result.assignedUser?.name || "-",
    locationName: result.location?.name || "-",
    payRateType: result.payRateType || "-",
    actualHours: toFixedWithoutZero(result.actualHours) || "-",
    scheduledHours: toFixedWithoutZero(result.scheduledHours) || "-",
    actualVsScheduled: result.actualVsScheduled.toFixed(3) || "-",
    otHours: result.otHours || "-",
    totalShifts: result.totalShifts || "-",
    missedShifts: result.missedShifts || "-"
  }));

  useEffect(() => {
    // useEffect has to be above any early returns
    // https://github.com/vercel/swr/discussions/1815
    if (data && data.results) {
      // This applies the filters to the just loaded issues.
      // This will also eventually set the filters to be loaded.
      rerenderFilters();
    } else {
      // Set filters to be loading when data is reset to null.
      setFilterLoading();
    }
  }, [data, results]);

  if (isLoading|| payPeriodIsLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error}/>;
  }

  // handle API specific errors
  if (data?.error) {
    return <ErrorPage errorMessage={data.error}/>;
  }

  return (
    <Flex
      flexDirection="column"
      height="100%"
    >
      <Heading
        as="h1"
        size="lg"
        marginLeft={6}
        marginRight={6}
        paddingTop={6}
        marginBottom={6}
      >
        <Flex flexDirection="row" alignContent="center" justifyContent="space-between">
          <Text>Timesheet Summary</Text>
        </Flex>
      </Heading>

      {/* Controls on top */}
      <Flex flexDirection="row" alignContent="center" justifyContent="space-between" marginLeft={6} marginRight={6}>
        <Flex>
          <PayPeriodSelect
            selectedPayPeriod={selectedPayPeriod}
            setSelectedPayPeriod={setSelectedPayPeriod}
            payPeriodOptions={payPeriodOptions}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
          /> 
          <SelectGroupBy />
        </Flex>
        <Flex flexDirection="row" align="center" height="75px">
          <CSVLink
            data={csvData}
            headers={Object.values(COLUMNS)}
            filename={`timesheet_summary_${timezoneContext.formatDate(selectedPayPeriod?.startDate, "MMM_d_yyyy")}_${timezoneContext.formatDate(selectedPayPeriod?.endDate, "MMM_d_yyyy")}`}
          >
            <Button marginRight={3} onClick={() => {}}>Export</Button>
          </CSVLink>
        </Flex>
      </Flex>
      
      {/* Filters */}
      <Box
        borderRadius="3xl"
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100px"
      >
        <Flex
          justifyContent="space-between"
          mx={6}
          mt={6}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text>Filter by:</Text>
            <Flex
              alignItems={"center"}
            >
              { showBuildingColumn ? (
                <Box mr={6}>
                  <EventFilter
                    key={"BuildingFilter" + resetKey}
                    isLoading={isBuildingFilterLoading}
                    events={Object.values(results)}
                    eventIdSetsFromOtherFilters={idSetsFromOtherFilters(buildingFilteredIds)}
                    getFilterKey={(result) => result.location?.name}
                    getEventId={(result) => result.id}
                    convertFilterKeyToLabel={(filterKey) => filterKey}
                    updateEvent={(ids) => {
                      setBuildingFilteredIds(ids);
                      setIsBuildingFilterLoading(false);
                    }}
                    filterName="Building"
                  />
                </Box>
              ) : null }
              { showEmployeeColumn ? (
                <Box mr={6}>
                  <EventFilter
                    key={"EmployeeFilter" + resetKey}
                    isLoading={isEmployeeFilterLoading}
                    events={Object.values(results)}
                    eventIdSetsFromOtherFilters={idSetsFromOtherFilters(employeeFilteredIds)}
                    getFilterKey={(result) => result.assignedUser?.name}
                    getEventId={(result) => result.id}
                    convertFilterKeyToLabel={(filterKey) => filterKey}
                    updateEvent={(ids) => {
                      setEmployeeFilteredIds(ids);
                      setIsEmployeeFilterLoading(false);
                    }}
                    filterName="Employee"
                  />
                </Box>
              ) : null }
              <Box mr={6}>
                <EventFilter
                  key={"PayRateTypeFilter" + resetKey}
                  isLoading={isStatusFilterLoading}
                  events={Object.values(results)}
                  eventIdSetsFromOtherFilters={idSetsFromOtherFilters(statusFilteredIds)}
                  getFilterKey={(result) => result.payRateType || "-"}
                  getEventId={(result) => result.id}
                  convertFilterKeyToLabel={(filterKey) => PayRateTypeMappings[filterKey] || filterKey}
                  updateEvent={(ids) => {
                    setStatusFilteredIds(ids);
                    setIsStatusFilterLoading(false);
                  }}
                  filterName="Pay Rate Type"
                />
              </Box>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setFilterLoading();
                  setSearchParams('');
                  rerenderFilters();
                }}
              >
                Clear all
              </Button>
            </Flex>
          </Flex>
          {/* <EventFilterTray
            data={results}
            filterDefs={[
              { getFilterKey: ((item: any) => PayRateTypeMappings[item.payRateType] || "-"), name: "Pay Rate Type" },
              ...(showBuildingColumn ? [{ getFilterKey: ((item: any) => item.location?.name), name: "Building" }] : []),
              ...(showEmployeeColumn ? [{ getFilterKey: ((item: any) => item.assignedUser?.name), name: "Employee" }] : []),
            ]}
            setFilteredData={setFilteredData}
          /> */}
        </Flex>
      </Box>

      {/* Table */}
      <Box
        borderRadius="3xl"
        flex={1}
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        marginTop={0}
        height="100%"
      >
        <Flex
          flexDirection="row"
          backgroundColor="secondary.lightGray"
          justifyContent="space-between"
          borderRadius={12}
          marginLeft={6}
          marginRight={6}
          marginTop={6}
          padding="16px"
        >
          <Flex flexDirection="column" flex={1}>
            <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">Actual Hours</Text>
            <Text fontSize="24px" fontWeight="medium">{toFixedWithoutZero(topLevelTotals.actualHours)}</Text>
          </Flex>
          <Flex flexDirection="column" flex={1}>
            <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">Scheduled Hours</Text>
            <Text fontSize="24px" fontWeight="medium">{toFixedWithoutZero(topLevelTotals.scheduledHours)}</Text>
          </Flex>
          <Flex flexDirection="column" flex={1}>
            <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">Actual vs Scheduled</Text>
            {/* <Text fontSize="24px" fontWeight="medium">50</Text> */}
            <ActualVsBudgetHighlight actualHours={toFixedWithoutZero(topLevelTotals.actualHours)} scheduledHours={toFixedWithoutZero(topLevelTotals.scheduledHours)} value={topLevelTotals.scheduledHours ? ((topLevelTotals.actualHours || 0.0) / topLevelTotals.scheduledHours) : null } />
          </Flex>
          <Flex flexDirection="column" flex={1}>
            <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">OT Hours</Text>
            <Text fontSize="24px" fontWeight="medium" className={topLevelTotals.otHours > 0 ? styles.highlightedNumber : ""}>{toFixedWithoutZero(topLevelTotals.otHours)}</Text>
          </Flex>
        </Flex>
        <Flex
          my={6}
          flex={1}
          flexDirection="column"
          overflowY="hidden"
        >
          <Flex className={styles.tableHeaderContainer}>
            <Table variant="simple" colorScheme="gray">
              <Thead position="sticky" top={0} bg="white" width="100%">
                <Tr>
                  {
                    Object.values(COLUMNS).map((column, index) => {
                      if (column.key === "locationName" && !showBuildingColumn) {
                        return null;
                      }

                      if (column.key === "assignedUserName" && !showEmployeeColumn) {
                        return null;
                      }

                      return (<Th width={column.width} textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={index === 0 || index === 1 ? 6 : 0} pr={0}>
                        <Flex flexDirection="row" alignItems="center">
                          <Text>{column.label}</Text>
                          { column.key === "otHours" ? (
                            <Tooltip label="OT Hours are shown only when grouped by Employee" fontSize="md" placement="top" shouldWrapChildren>
                              <HiOutlineExclamationCircle size={14} style={{ marginLeft: "2px" }} />
                            </Tooltip>
                          ) : null }
                        </Flex>
                      </Th>)
                    })
                  }
                </Tr>
              </Thead>
            </Table>
          </Flex>
          <Flex
            overflowY="scroll"
          >
            <Table variant="simple" colorScheme="gray">
              <Tbody>
                {(filteredData || []).map((result: any) => (
                  <Tr width="100%"
                    key={result.id}
                    onClick={() => onRowClick(result)} style={{ cursor: 'pointer' }}
                    _hover={{ bg: 'gray.50' }}
                  >
                    { showEmployeeColumn ? <Td width="15%" pl={6}>{result.assignedUser?.name || "-"}</Td> : null }
                    { showBuildingColumn ? <Td width="15%" pl={6}>{result.location?.name || "-"}</Td> : null }
                    <Td width="10%" pl={0}>
                      <Text noOfLines={2} wordBreak="break-all">{PayRateTypeMappings[result.payRateType] || "-"}</Text>
                    </Td>
                    <Td width="10%" pl={0}>{toFixedWithoutZero(result.actualHours) || "-"}</Td>
                    <Td width="10%" pl={0}>{toFixedWithoutZero(result.scheduledHours) || "-"}</Td>
                    <Td width="10%" pl={0}>
                      <ActualVsBudgetHighlightInTable value={result.actualVsScheduled} actualHours={toFixedWithoutZero(result.actualHours)} scheduledHours={toFixedWithoutZero(result.scheduledHours)} />
                    </Td>
                    <Td width="10%" pl={0} className={result.otHours > 0 ? styles.highlightedNumber : ""}>{result.otHours ? toFixedWithoutZero(result.otHours) : "-"}</Td>
                    <Td width="10%" pl={0}>{result.totalShifts}</Td>
                    <Td width="10%" pl={0} className={result.missedShifts > 0 ? styles.highlightedNumber : ""}>{result.missedShifts || "-"}</Td>
                    {/* <Td width="10%" pl={0} >{result.estimatedGrossPay || "-"}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default TimesheetDashboard;
