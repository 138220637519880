import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { Box, Button, Flex, Heading, Icon, Text, useTheme } from "@chakra-ui/react";
import { BsArrowLeftShort } from "react-icons/bs";

import { useAuth } from "@/contexts/auth_context";

import { BuildingInfo } from "@/components/BuildingInfo";
import { AttendanceCharts, InspectionsCharts, IssueTrackingCharts } from "@/components/Charts";
import { ErrorPage } from "@/components/ErrorPage";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import { HealthScoreSidebar } from "@/components/HealthScoreSidebar";

import styles from "./styles.module.scss";

const BuildingPage = () => {
  const { building_id } = useParams();
  const buildingId = parseInt(building_id || "");
  const authContext = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const scrollableContentRef = useRef();


  const getBuildingUrl = (buildingId: number) => {
    return `${import.meta.env.VITE_API_SERVER}/locations/${buildingId}/metrics`
  }

  const { data, error, isLoading } = useSWR(
    getBuildingUrl(buildingId),
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  useEffect(() => {
    const handleScroll = (event) => {
      if (scrollableContentRef.current) {
        scrollableContentRef.current.scrollTop += event.deltaY;
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error} />;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error} />;
  }

  return (
    <Flex
      flexDirection={"column"}
      height={"100%"}
      padding={"2.25em 1.75em"}
      overflow={"hidden"}
    >
      <Flex
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Icon
          className={styles.icon}
          onClick={() => navigate(`/buildings/`)}
          as={BsArrowLeftShort}
          boxSize={10}
          marginRight={"1em"}
          color={"primary.900"}
          cursor={"pointer"}
        />
        <Heading
          as={"h1"}
          size={"lg"}
          margin={0}
        >
          <Text>{data.location.name}</Text>
        </Heading>
      </Flex>
      <Flex
        marginTop={"1.5em"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        height={"100%"}
        position={"relative"}
      >
        <Flex
          flexDirection={"column"}
          flex={"1"}
        >
          <Box
            ref={scrollableContentRef}
            className={styles.noScrollbar}
            height={"100%"}
            width={"70.5%"}
            overflowY={"scroll"}
            paddingBottom={"3.5em"}
          >
            <Heading
              size={"md"}
              fontWeight={"medium"}
              marginBottom={"1em"}
            >
              <Text fontWeight={"medium"}>Building Info</Text>
            </Heading>
            <BuildingInfo data={data} />
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              margin={"1em 0em"}
            >
              <Text fontSize={"xl"} fontWeight={"medium"} marginBottom={0}>
                Inspections
              </Text>
              <Button
                variant={"ghost"}
                onClick={() => window.open(`/inspection?Building=${encodeURIComponent(data.location.name)}`, '_blank')}
              >
                View Inspections
              </Button>
            </Flex>
            <InspectionsCharts locationId={buildingId} />
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              margin={"1em 0em"}
            >
              <Text fontSize={"xl"} fontWeight={"medium"} marginBottom={0}>
                Attendance
              </Text>
              <Button
                variant={"ghost"}
                onClick={() => window.open(`/clock_in_dashboard/${new Date().toJSON().slice(0, 10)}?Location=${encodeURIComponent(data.location.name)}`, '_blank')}
              >
                View Attendance
              </Button>
            </Flex>
            <AttendanceCharts locationId={buildingId} />
            <Flex
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              margin={"1em 0em"}
            >
              <Text fontSize={"xl"} fontWeight={"medium"} marginBottom={0}>
                Issue Tracking
              </Text>
              <Button
                variant={"ghost"}
                onClick={() => window.open(`/issues?Building=${encodeURIComponent(data.location.name)}`, '_blank')}
              >
                View Issues
              </Button>
            </Flex>
            <IssueTrackingCharts locationId={buildingId} />
          </Box>
        </Flex>
        <Flex
          position={"absolute"}
          right={0}
          paddingTop={"3em"}
          width={"28%"}
        >
          <HealthScoreSidebar location={data.location} />
        </Flex>
      </Flex>
    </Flex >
  )
}

export default BuildingPage;
