import { useState } from 'react';

import { Button, Heading, Icon, Text, Textarea } from '@chakra-ui/react';
import { BsClockFill, BsFillExclamationCircleFill, BsPinFill } from 'react-icons/bs';
import { useTimezone } from '@/contexts/timezone_context';

import { useAuth } from '@/contexts/auth_context';

import styles from "./styles.module.scss";

const AttendanceTaskDetail = ({ task, shifts, completedTaskCallback }) => {
    const authContext = useAuth();
    const timezoneContext = useTimezone();
    const [callSummary, setCallSummary] = useState("")

    const handleCompleteTask = async () => {
        const url = `${import.meta.env.VITE_API_SERVER}/mobile/v1/attendance_tasks/${task.id}/complete/`;

        try {
            const response = await authContext.authenticatedFetch(
                url,
                {
                    method: "PATCH",
                    body: JSON.stringify({ completion_notes: callSummary })
                }
            )

            if (response.status != 204) {
                throw new Error(`Unexpected status code: ${response.status}\n\n${response}`);
            }

            completedTaskCallback(task.id)
        } catch (error) {
            console.error(error);
            // TODO: Handle error in UI
        }
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.warningContainer}>
                <Icon className={styles.icon} as={BsFillExclamationCircleFill} boxSize={16} color={"secondary.red"} />
                <div>
                    <Heading fontSize={18} fontWeight={500}>Remind {task.assignee.name} to clock in and out on time</Heading>
                    {!!task.escalationMessage && <Text className={styles.subtitle}>{task.escalationMessage}</Text>}
                </div>
            </div>
            <Heading className={styles.heading} fontSize={20} fontWeight={500}>Missed Clock-Ins and Clock-Outs</Heading>
            <div className={styles.shiftsContainer}>
                {task.shiftIds.map((shiftId, index) => (
                    <div className={styles.shift} key={shiftId}>
                        <div className={styles.indexContainer}>
                            <Heading fontSize={16} fontWeight={500}>{index + 1}</Heading>
                        </div>
                        <div className={styles.infoContainer}>
                            <Icon className={styles.icon} as={BsClockFill} color={"primary.900"} />
                            <Text className={styles.text}>
                                {timezoneContext.formatDate(shifts[shiftId].calendarStartDatetime, "MMM d, yyyy")}
                            </Text>
                            <Icon className={styles.icon} as={BsPinFill} color={"primary.900"} />
                            <Text className={styles.text}>{shifts[shiftId].building}</Text>
                        </div>
                    </div>
                ))
                }
            </div>
            <Heading className={styles.heading} fontSize={20} fontWeight={500}>Call Summary</Heading>
            <Textarea
                className={styles.callSummary}
                value={callSummary}
                onChange={event => setCallSummary(event.target.value)}
                placeholder='Start to type'
            />
            <Button
                className={styles.button}
                onClick={handleCompleteTask}
                isDisabled={callSummary == ""}
            >
                Complete Task
            </Button>
        </div>
    );
}

export default AttendanceTaskDetail;
