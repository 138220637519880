import { DateSeparator, DateSeparatorProps } from "stream-chat-react";

export const CenteredDateSeparator = (props: DateSeparatorProps) => {
    const { date } = props

    return (
        <DateSeparator
            date={date}
            position={'center'}
        />
    )
};
