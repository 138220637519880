import { Button, Icon, Text } from '@chakra-ui/react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import styles from "./styles.module.scss";

const AttendanceTaskListItem = ({ task }: { id: number, name: string }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.taskContainer}>
            <Icon className={styles.icon} as={BsFillExclamationCircleFill} boxSize={9} color={"secondary.red"} />
            <Text className={styles.taskName} fontSize="lg">{task.taskName}</Text>
            <Button className={styles.button} onClick={() => navigate(`/employee/${task.employee.id}`)}>Go To Task</Button>
        </div>
    );
}

export default AttendanceTaskListItem;
