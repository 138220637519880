import React, { useRef } from "react";
import { Avatar as DefaultAvatar, ChannelPreviewUIComponentProps } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

import styles from "./styles.module.scss"

const ChannelPreviewMessenger = <
    StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(props: ChannelPreviewUIComponentProps<StreamChatGenerics>,) => {
    const {
        active,
        Avatar = DefaultAvatar,
        channel,
        displayImage,
        displayTitle,
        latestMessage,
        onSelect: customOnSelectChannel,
        setActiveChannel,
        unread,
        watchers,
    } = props;

    const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

    const avatarName =
        displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

    const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (customOnSelectChannel) {
            customOnSelectChannel(e);
        } else if (setActiveChannel) {
            setActiveChannel(channel, watchers);
        }
        if (channelPreviewButton?.current) {
            channelPreviewButton.current.blur();
        }
    };

    let displayText = (latestMessage == "Nothing yet...") ? "" : latestMessage

    return (
        <button
            className={styles.channelPreview + " " +
                (active && styles.channelPreviewActive) + " " +
                (unread && unread >= 1 && styles.channelPreviewUnread)
            }
            onClick={onSelectChannel}
            ref={channelPreviewButton}
        >
            <div>
                <Avatar image={displayImage} name={avatarName} size={40} />
            </div>
            <div className={styles.channelPreviewRight}>
                <div className={styles.channelPreviewRow}>
                    <div className={styles.channelPreviewName}>
                        <span>{displayTitle}</span>
                    </div>
                </div>
                <div className={styles.channelPreviewRow}>
                    <div className={styles.channelPreviewLastMessage}>{displayText}</div>
                </div>
            </div>
            {!!unread && (
                <div className={styles.channelPreviewUnreadBadge}>
                    {unread}
                </div>
            )}
        </button>
    );
};

export const CustomChannelPreviewMessenger = React.memo(
    ChannelPreviewMessenger,
) as typeof ChannelPreviewMessenger;