import useSWR from "swr";

import ReactECharts from 'echarts-for-react';
import { Box, Text, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";
import ChartLoadingState from "./loading_state";

import styles from "./styles.module.scss";

const DaysInRedChart = ({ }) => {
  const authContext = useAuth();
  const theme = useTheme();

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/health_scores/buildings_in_red`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return <ChartLoadingState
      spinnerSize={"4em"}
      backgroundColor={"white"}
      border={`1px solid ${theme.colors.gray[200]}`}
      height={"100%"}
      width={"32%"}
    />;
  }

  const sortedLocations = !data.dataSeries ?
    [] :
    data.dataSeries.sort(function (a, b) {
      return a.daysInRed > b.daysInRed
    })

  const { xAxis, yAxis } = sortedLocations.reduce((acc, item) => {
    acc.xAxis.push(item.daysInRed);
    acc.yAxis.push(data.locations[item.locationId].name);
    return acc;
  }, { xAxis: [], yAxis: [] });

  let option = {
    textStyle: {
      fontFamily: "DM Sans",
    },
    grid: {
      top: "0%",
      left: "40%",
      bottom: "20%",
      right: "5%",
    },
    xAxis: {
      type: 'value',
      name: "Days in Red",
      nameLocation: "middle",
      nameGap: 30,
      minInterval: 1,
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: yAxis,
      max: 4,
      axisLabel: {
        color: "black",
        width: 130,
        overflow: "truncate",
        nameTruncate: {
          maxWidth: 130,
          ellipsis: "..."
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: false,
      },
    },
    tooltip: {
      show: true,
      trigger: "item",
      confine: true,
      className: styles.toolTip,
      formatter: (params) => {
        return `
        <div style="display: flex; flex-direction: column;">
            <div style="display: flex; justify-content: space-between;">
              <div style="flex: 0 1 auto; text-align: left;">${params.name}</div>
            </div>
        </div>
        `
      },
    },
    series: [
      {
        realtimeSort: true,
        type: 'bar',
        data: xAxis,
        label: {
          show: true,
          position: 'inside',
          valueAnimation: true
        },
        itemStyle: {
          barBorderRadius: [0, 4, 4, 0],
        }
      }
    ],
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1em"}
      width={"32%"}
      maxHeight={"25em"}
    >
      <Text fontSize={"xl"} fontWeight={"medium"} marginBottom={"0.5em"}>At Risk Buildings</Text>
      <ReactECharts option={option} style={{ height: '85%' }} />
    </Box>
  )
}

export default DaysInRedChart;
