import { PhotoList } from "@/components/PhotoList";
import { Flex, Text, Textarea, Button, Box, useTheme } from "@chakra-ui/react";

import styles from "./styles.module.scss";
import { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IssueDetailContext } from "@/views/IssueDetailPage/issue_detail_page";

import { FiPaperclip } from "react-icons/fi";

const CommentInput = () => {
  const [comment, setComment] = useState<string>('');
  const [attachmentsToUpload, setAttachmentsToUpload] = useState<Array<any>>([]);
  const [attachments, setAttachments] = useState<Array<{ url: string; filename: string; }>>([]);

  const issueDetailContext = useContext(IssueDetailContext);
  const { setSlides, setLightboxIndex, addComment } = issueDetailContext;

  const theme = useTheme();

  const handleDrop = (acceptedFiles: Array<any>) => {
    setAttachmentsToUpload(attachments => [...attachments, ...acceptedFiles]);
    setAttachments((currentAttachments) => [...currentAttachments, ...(acceptedFiles.map((file) => ({ url: URL.createObjectURL(file), filename: file.name })))])
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: {
      'image/*': []
    },
  });

  return (
    <Flex flexDirection="column">
      <Textarea
        placeholder="Add your comment here"
        width={"100%"}
        size="md"
        value={comment}
        onChange={(event) => {
          setComment(event.target.value);
        }}
        minHeight="40px"
      />
      <Flex flexDirection="row" alignItems="flex-end" justifyContent="space-between" className={styles.buttonContainer}>
        <PhotoList
          urls={attachments.map((item) => item.url)}
          onRemove={(photoToRemove: string, index: number) => {
            const attachmentsFilenamesToRemove = attachments.filter(item => item.url === photoToRemove).map((item) => item.filename);
            setAttachments(currentAttachments => currentAttachments.filter((item, idx) => idx !== index));
            
            const indexToRemove = attachmentsToUpload.findIndex(file => attachmentsFilenamesToRemove.includes(file.name));
            setAttachmentsToUpload(currentPhotosToUpload => currentPhotosToUpload.filter((item, idx) => idx !== indexToRemove));
          }}
          canEdit={attachments.length > 0}
          photoSelectorTrigger={open}
          onClickPhoto={(photos, photo) => {
            setSlides(photos);
            setLightboxIndex(photo.photoIndex);
          }}
        />
        {/* the input needs to be rendered here to work on Firefox & Safari - https://github.com/react-dropzone/react-dropzone/issues/1294#issuecomment-2173092399 */}
        <input type="file" {...getInputProps()} style={{ display: "none" }} />
        {
          attachments.length === 0 ? (
            <Flex className={styles.attachPhotoButton} {...getRootProps()} onClick={open}>
              <FiPaperclip color={theme.colors.primary[500]} style={{ marginRight: 4 }} height={16} width={16} />
              <Text>Attach photos</Text>
            </Flex>
          ) : null
        }

        <Button
          onClick={() => {
            addComment({ text: comment, attachments: attachmentsToUpload });
            setComment("");
            setAttachments([]);
            setAttachmentsToUpload([]);
          }}
          isDisabled={comment === "" && attachments.length < 1}
        >
          Send comment
        </Button>
      </Flex>
    </Flex>
  );
};

export default CommentInput;
