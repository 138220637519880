import { Document, Page, View as PdfView, Text as PdfText, Image as PdfImage } from '@react-pdf/renderer';
import thumbsUp from '../../assets/thumbs_up.png';
import thumbsDown from '../../assets/thumbs_down.png';
import unrated from '../../assets/unrated.png';

const styles = {
  page: {
    padding: 12,
  },
  header: {
    marginBottom: 6,
    paddingBottom: 6,
    borderBottom: '1 solid black',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  headerSubtitle: {
    fontSize: 12,
    color: 'gray',
    marginVertical: 3,
  },
  content: {
    marginBottom: 12,
  },
  area: {
    marginVertical: 6,
    borderBottom: '0.2 solid black',
  },
  areaTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  item: {
    marginVertical: 6,
    flexDirection: 'column',
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 3,
  },
  ratingIcon: {
    width: 20,
    height: 20,
    marginRight: 5,
  },
  itemName: {
    fontSize: 12,
  },
  comment: {
    fontSize: 10,
  },
  photo: {
    maxWidth: '200',
    margin: 2,
  },
  photosContainer: {
    flexDirection: 'column',
  },
};

const InspectionPdf = ({ inspection, inspectionForm, location }) => {
  const getRatingImage = (rating) => {
    switch (rating) {
      case 'thumbs_up':
        return thumbsUp;
      case 'thumbs_down':
        return thumbsDown;
      default:
        return unrated;
    }
  };
  return (
    <Document>
      <Page size="A4">
        <PdfView style={styles.page}>
          <PdfView style={styles.header}>
            <PdfText style={styles.headerTitle}>{location.name}</PdfText>
            <PdfView>
              <PdfText style={styles.headerSubtitle}>Address: {location.address}</PdfText>
              <PdfText style={styles.headerSubtitle}>Score: {Math.round(inspection.score * 100)}</PdfText>
              {/* Can't migrate this to useTimezone() because this component is not used in TimezoneProvider */}
              <PdfText style={styles.headerSubtitle}>Date: {new Date(inspection.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}</PdfText>
            </PdfView>
          </PdfView>

          <PdfView style={styles.content}>
            {inspectionForm.areas.map((area) => (
              <PdfView key={area.id} style={styles.area}>
                <PdfText style={styles.areaTitle}>{area.name}</PdfText>
                {area.items.map((item) => (
                  <PdfView key={item.id} style={styles.item}>
                    <PdfView style={styles.itemRow}>
                      <PdfImage style={styles.ratingIcon} src={getRatingImage(item.rating)} />
                      <PdfText style={styles.itemName}>{item.name}</PdfText>
                    </PdfView>
                    {item.comment && <PdfText style={styles.comment}>{item.comment}</PdfText>}
                    <PdfView style={styles.photosContainer}>
                      {item.photoUrls.map((photoUrl, index) => (
                        <PdfImage
                          key={index}
                          source={{
                            uri: photoUrl,
                            // Required for CORS
                            // https://github.com/diegomura/react-pdf/issues/766
                            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
                          }}
                          style={styles.photo}
                        />
                      ))}
                    </PdfView>
                  </PdfView>
                ))}
              </PdfView>
            ))}
          </PdfView>
        </PdfView>
      </Page>
    </Document>
  );
};

export default InspectionPdf;
