import { useAuth } from "@contexts/auth_context";
import { Heading, Text, Flex, Table, Thead, Tbody, Tr, Th, Td, useTheme, Button, Box, Icon } from "@chakra-ui/react";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import {ErrorPage} from "@/components/ErrorPage";
import useSWR, { mutate} from "swr";
import { useState } from "react";
import styles from "./styles.module.scss";
import { UserEditModal } from "@/components/UserEditModal";
import { formatPhoneNumber } from "@/utils/formatting";
import { FaCircleCheck } from "react-icons/fa6";

const USERS_INDEX_URL = `${import.meta.env.VITE_API_SERVER}/users`;

const UserDashboard: React.FC = () => {
  const authContext = useAuth();
  const theme = useTheme();
  const [ userToBeEdited, setUserToBeEdited ] = useState(null);
  const [ isEditModalOpen, setIsEditModalOpen ] = useState<boolean>(false);

  const { data, error, isLoading } = useSWR(
    USERS_INDEX_URL,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then(response => response.json())
    }
  );

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error}/>;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error}/>;
  }

  const onRowClick = (userId) => {
    setUserToBeEdited(userId);
    setIsEditModalOpen(true);
  };

  const { users } = data;

  const allUsers = Object.values(users).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  })

  return (
    <Flex
      flexDirection={"column"}
      height="100%"
    >
      <Flex
        flexDirection={"row"}
        pt={6}
        mx={6}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading
          as="h1"
          size="lg"
          marginBottom={0}
        >
          <Text>Users</Text>
        </Heading>
        <Button
            isDisabled={isLoading}
            onClick={() => {
              setIsEditModalOpen(true);
          }}>
            Create user
        </Button>
      </Flex>
      <Box
        borderRadius="3xl"
        flex={1}
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100%"
      >
        <Flex
          my={6}
          flex={1}
          flexDirection="column"
          overflowY="hidden"
        >
          <Flex className={styles.tableHeaderContainer}>
            <Table variant="simple" colorScheme="gray">
              <Thead position="sticky" top={0} bg="white">
                <Tr>
                  <Th width="30%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={6} >Name</Th>
                  <Th width="30%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Username</Th>
                  <Th width="30%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Phone Number</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} style={{ textAlign: "center" }} >Supervisor</Th>
                </Tr>
              </Thead>
            </Table>
          </Flex>
          <Flex
            overflowY="scroll"
          >
            <Table variant="simple" colorScheme="gray">
              <Tbody>
                {allUsers.map((user) => (
                  <Tr width="100%"
                    key={user.id}
                    onClick={() => onRowClick(user.id)}
                    _hover={{ bg: 'gray.50', cursor: "pointer" }}
                  >
                    <Td width="30%" pl={6} >{user.name}</Td>
                    <Td width="30%" pl={0} >{user.username}</Td>
                    <Td width="30%" pl={0} >{formatPhoneNumber(user.phoneNumber)}</Td>
                    <Td width="10%" pl={0} style={{ textAlign: "center" }}>{user.isSupervisor && <Icon as={FaCircleCheck} color="gray" />}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      </Box>
      <UserEditModal
        isOpen={isEditModalOpen}
        user={userToBeEdited ? users[userToBeEdited] : null}
        onClose={() => {
          setIsEditModalOpen(false)
          setUserToBeEdited(null)
          mutate(USERS_INDEX_URL);
        }}
      />
    </Flex>
  );
};

export default UserDashboard;
