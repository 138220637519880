import { useAuth } from "@contexts/auth_context";
import {
  Card,
  Heading, Divider,
  Flex, Spacer,
  Text, Button,
  useTheme, Box
} from "@chakra-ui/react";

import styles from "./styles.module.scss";
import {
  addDays, subDays,
} from "date-fns";
import { AiOutlineReload } from "react-icons/ai";
import { useState, useEffect } from "react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { FullScreenSpinner } from '@/components/FullScreenSpinner';
import { ClockInEmployeeCard } from '@/components/ClockInEmployeeCard';
import { EventFilter } from '@/components/EventFilter';
import { ErrorPage } from '@/components/ErrorPage';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { AttendanceTaskList } from "@/components/AttendanceTaskList";
import { DateTimePicker } from "@/components/DateTimePicker";
import { useTimezone } from "@/contexts/timezone_context";

const getUrl = (supervisorId: number, inputDate: Date) => {
  const startDatetime = subDays(inputDate, 0).toISOString();
  const endDatetime = addDays(inputDate, 1).toISOString();

  return `${import.meta.env.VITE_API_SERVER}/assignments/clock_ins_for_team.json?supervisor_id=${supervisorId}&start_datetime=${encodeURIComponent(
    startDatetime
  )}&end_datetime=${encodeURIComponent(endDatetime)}`;
}

const ClockInDashboard = () => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();
  const { date } = useParams();
  // Need to set milliseconds here because we are using the URL as the key for SWR
  const today = timezoneContext.startOfDay(new Date());
  const [startDatetime, setStartDatetime] = useState<Date>(date ? timezoneContext.parse(date) : today);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const userInfo = authContext.state.userToken.user_info;
  const developerFeatureEnabled = authContext.state.features?.developerFeature || false

  // Filtering
  const [supervisorFilteredEventIds, setSupervisorFilteredEventIds] = useState<Set<string>>(new Set());
  const [statusFilteredEventIds, setStatusFilteredEventIds] = useState<Set<string>>(new Set());
  const [employeeFilteredEventIds, setEmployeeFilteredEventIds] = useState<Set<string>>(new Set());
  const [locaitonFilteredEventIds, setLocationFilteredEventIds] = useState<Set<string>>(new Set());
  const [detachedFilteredEventIds, setDetachedFilteredEventIds] = useState<Set<string>>(new Set());

  // https://stackoverflow.com/questions/67273965/how-to-reset-and-re-render-components-using-react-hooks
  const [resetKey, setResetKey] = useState<boolean>(false);

  useEffect(() => {
    if (!date) {
      navigate(`/clock_in_dashboard/${timezoneContext.formatDate(today, 'yyyy-MM-dd')}?${searchParams.toString()}`, { replace: true });
    }
  }, [date]);

  const setStartDateTimeAndUpdateUrl = (newStartDate: Date) => {
    if (newStartDate.getTime() == startDatetime.getTime()) {
      return;
    }
    setStartDatetime(newStartDate);
    navigate(`/clock_in_dashboard/${timezoneContext.formatDate(newStartDate, 'yyyy-MM-dd')}?${searchParams.toString()}`);
  };

  const eventIdSetsFromOtherFilters = (eventIdSetForCurrentFilter) => {
    return [supervisorFilteredEventIds, statusFilteredEventIds, employeeFilteredEventIds, locaitonFilteredEventIds, detachedFilteredEventIds]
      .filter((eventIdSet) => {
        return eventIdSet != eventIdSetForCurrentFilter;
      });
  }

  // Unique identifier for each event
  const getEventId = (event) => {
    return event.clockedShift?.shiftId + "-" + event.assignmentId + "-" + event.eventInfo.calendarTime;
  }

  const rerenderFilters = () => {
    // hide all events before rerendering to avoid flickering.
    setSupervisorFilteredEventIds(new Set());
    setStatusFilteredEventIds(new Set());
    setEmployeeFilteredEventIds(new Set());
    setLocationFilteredEventIds(new Set());
    setDetachedFilteredEventIds(new Set());
    setResetKey(!resetKey);
  }

  const renderCompletedPercentage = (events) => {
    if (!events) {
      return null
    }

    const total = Object.values(events).flat().filter(event => event.status !== "not_tracked").length;
    if (!total) {
      return null
    }

    const completed = Object.values(events).flat().filter(event => event.status === "completed").length;
    const percentage = ((completed / total) * 100).toFixed(0);
    const text = `${percentage}% completed (${completed}/${total})`;

    const getColorForPercentage = (percentage) => {
      if (percentage < 50) return 'black';
      if (percentage >= 100) return 'green';

      // interpolate the color between black and green
      const ratio = (percentage - 50) / (100 - 50);
      const green = 255 * ratio;
      return `rgb(0, ${Math.round(green)}, 0)`;
    };

    const getEmojiForPercentage = (percentage) => {
      if (percentage >= 95) return '🎉';
      if (percentage >= 80) return '🚀';
      return '';
    };

    return <Text textColor={getColorForPercentage(percentage)}>{text + " " + getEmojiForPercentage(percentage)}</Text>
  }

  const fetchClockInsForTeam = async (url: String) => {
    const response = await authContext.authenticatedFetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const employees = data.employees.map((e) => ({
      id: e.id,
      name: e.name,
      phoneNumber: e.phoneNumber,
    }));

    let assignmentsByEmployee = data.assignmentsByEmployee || {};

    // Remove anyone who is not scheduled for an assignment
    assignmentsByEmployee = Object.keys(assignmentsByEmployee)
      .filter((key) => assignmentsByEmployee[key].length > 0)
      .reduce((obj, key) => {
        obj[key] = assignmentsByEmployee[key];
        return obj;
      }, {});
    return { employees, assignmentsByEmployee };
  };

  const { data, error, isLoading } = useSWR(
    getUrl(userInfo.id, startDatetime),
    url => fetchClockInsForTeam(url)
  );

  useEffect(() => {
    // useEffect has to be above any early returns
    // https://github.com/vercel/swr/discussions/1815
    if (data && data.assignmentsByEmployee) {
      rerenderFilters();
    }
  }, [data]);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  const { assignmentsByEmployee, employees } = data;
  Object.keys(assignmentsByEmployee).forEach(key => {
    assignmentsByEmployee[key].forEach(event => {
      if (event.janitor.lastClientRequestAt === null && event.status !== "not_tracked") {
        event.status = "not_installed";
      }
    });
  });

  const eventList = [].concat(...Object.values(assignmentsByEmployee));
  let filteredEvents = {}
  let keys: Array<string> = Object.keys(assignmentsByEmployee);
  keys.forEach(key => {
    filteredEvents[key] = [];
    assignmentsByEmployee[key].forEach(event => {
      if (supervisorFilteredEventIds.has(getEventId(event)) &&
        statusFilteredEventIds.has(getEventId(event)) &&
        locaitonFilteredEventIds.has(getEventId(event)) &&
        detachedFilteredEventIds.has(getEventId(event)) &&
        employeeFilteredEventIds.has(getEventId(event))) {
        filteredEvents[key].push(event);
      }
    });
  });

  // remove anyone who doesn't have an event to show
  filteredEvents = Object.keys(filteredEvents)
    .filter(key => filteredEvents[key].length > 0)
    .reduce((obj, key) => {
      obj[key] = filteredEvents[key];
      return obj;
    }, {});

  const sortedEmployees = employees.sort((a, b) => {
    const getHighestStatus = (events) => {
      const validStatuses = ["not_installed", "not_tracked", "completed", "upcoming", "clocked_in", "no_show"];
      // get highest status among all events
      const highestStatus = events.reduce((highestStatus, event) => {
        if (validStatuses.indexOf(event.status) > validStatuses.indexOf(highestStatus)) {
          return event.status;
        }
        return highestStatus;
      }, "not_installed");
      return validStatuses.indexOf(highestStatus);
    };

    const aEvents = filteredEvents[a.id] || [];
    const bEvents = filteredEvents[b.id] || [];
    const aStatus = getHighestStatus(aEvents);
    const bStatus = getHighestStatus(bEvents);

    return bStatus - aStatus;
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Flex minWidth='max-content' alignItems='center' gap='2'>
          <Spacer />
          <Heading className={styles.title} size='lg'>
            Attendance Review
          </Heading>
          <Spacer />
        </Flex>

        {authContext.state?.features?.attendanceTask && <AttendanceTaskList />}
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Flex minWidth='max-content' alignItems='center' marginBottom='1em'>
          <Text fontSize='2xl' marginBottom={0}>{timezoneContext.formatDate(startDatetime, "iii, MMM dd, yyyy")}</Text>
          <Spacer />
          <Button
            onClick={() => setStartDateTimeAndUpdateUrl(subDays(startDatetime, 1))}
            variant={'outline'}
            mr={2}
          >
            <AiFillLeftCircle size={24} />
          </Button>
          <Flex>
            <DateTimePicker
              value={startDatetime}
              onChange={setStartDateTimeAndUpdateUrl}
              showTime={false}
              showDate={true}
            />
          </Flex>
          <Button
            onClick={() => setStartDateTimeAndUpdateUrl(addDays(startDatetime, 1))}
            variant={'outline'}
            ml={2}
          >
            <AiFillRightCircle size={24} />
          </Button>
        </Flex>

        <Flex alignItems="center" justifyContent='space-between'>
          <Flex alignItems={"center"}>
            <Text fontSize='lg' style={{ marginRight: 10 }}>Filter by:</Text>
            <Box mr={6}>
              <EventFilter
                key={"StatusFilter" + resetKey}
                events={eventList}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(statusFilteredEventIds)}
                getFilterKey={(event) => (event.status)}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => {
                  switch (filterKey) {
                    case "no_show":
                      return "No Show";
                    case "completed":
                      return "Completed";
                    case "clocked_in":
                      return "Clocked-In";
                    case "upcoming":
                      return "Upcoming";
                    case "not_tracked":
                      return "Not Tracked";
                    case "not_installed":
                      return "Not Installed";
                    default:
                      return filterKey;
                  }
                }}
                updateEvent={setStatusFilteredEventIds}
                filterName={"Clock-In Status"}
                width={160}
              />
            </Box>
            <Box mr={6}>
              <EventFilter
                key={"SupervisorFilter" + resetKey}
                events={eventList}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(supervisorFilteredEventIds)}
                getFilterKey={(event) => event.supervisor.name}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={setSupervisorFilteredEventIds}
                filterName={"Supervisor"}
                width={160}
              />
            </Box>
            <Box mr={6}>
              <EventFilter
                key={"Location" + resetKey}
                events={eventList}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(locaitonFilteredEventIds)}
                getFilterKey={(event) => { return event.location.name }}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={setLocationFilteredEventIds}
                filterName={"Location"}
                width={160}
              />
            </Box>
            <Box mr={6}>
              <EventFilter
                key={"Employee" + resetKey}
                events={eventList}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(employeeFilteredEventIds)}
                getFilterKey={(event) => { return event.janitor.name }}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={setEmployeeFilteredEventIds}
                filterName={"Cleaner"}
                width={160}
              />
            </Box>
            <Box mr={6}>
              <EventFilter
                key={"Detached" + resetKey}
                visible={developerFeatureEnabled}
                events={eventList}
                eventIdSetsFromOtherFilters={eventIdSetsFromOtherFilters(detachedFilteredEventIds)}
                getFilterKey={(event) => { return event?.eventInfo?.detached ? 'True' : 'False' }}
                getEventId={getEventId}
                convertFilterKeyToLabel={(filterKey) => filterKey}
                updateEvent={setDetachedFilteredEventIds}
                filterName={"Detached"}
                width={developerFeatureEnabled ? 160 : 0}
              />
            </Box>
            {renderCompletedPercentage(filteredEvents)}
          </Flex>

          <Button
            variant={'outline'}
            colorScheme={'gray'}
            leftIcon={<AiOutlineReload color={theme.colors.white["600"]} />}
            onClick={() => {
              setSearchParams('');
              rerenderFilters();
            }}
          >
            Reset Filters
          </Button>
        </Flex>
      </div>
      <div className={styles.clockInReviewList}>
        {
          sortedEmployees.map(employee => {
            if (!filteredEvents || !filteredEvents[employee.id]) {
              return null;
            }
            return <Card className={styles.employeeCard} key={`employee-card-${employee.id}`}>
              <ClockInEmployeeCard
                employee={employee}
                events={filteredEvents[employee.id]}
                refreshData={() => mutate(getUrl(userInfo.id, startDatetime))}
              />
            </Card>
          })
        }

      </div>

    </div>
  );
};

export default ClockInDashboard;
