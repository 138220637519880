import { Flex, Box, Text, Icon } from '@chakra-ui/react';
import { FaExclamationCircle } from 'react-icons/fa';

const ErrorView = ({ message }: { message: string }) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box textAlign="center" p={8} maxWidth="400px">
        <Icon as={FaExclamationCircle} w={12} h={12} color="gray.300" mb={4} />
        <Text fontSize="lg" fontWeight="bold" color="gray.500">
          {message}
        </Text>
      </Box>
    </Flex>
  );
};

export default ErrorView;
