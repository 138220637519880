import AttendanceTaskHistoryItem from './attendance_task_history_item';
import { AttendanceTaskMissing } from '../AttendanceTaskMissing';

import styles from "./styles.module.scss";

const AttendanceTaskHistory = ({ tasks, expanded = false }) => {
    if (!tasks) { return <AttendanceTaskMissing /> }

    return (
        <div className={styles.mainContainer}>
            {!!tasks && Object.values(tasks).map(task => {
                return <AttendanceTaskHistoryItem
                    key={task.id}
                    task={task}
                    expanded={expanded}
                />
            })}
        </div>
    );
}

export default AttendanceTaskHistory;
