import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

import { captureException } from "@sentry/react";

import { ErrorPage } from "@/components/ErrorPage";


const RouterErrorPage = () => {
    const error = useRouteError();

    useEffect(() => {
        captureException(error, { level: 'fatal' })
    }, [error])

    return <ErrorPage error={error} />;
}

export default RouterErrorPage;
