import { Icon, Text } from '@chakra-ui/react';
import { BsCalendarFill, BsChatFill, BsDashCircleFill, BsPersonCircle, BsPlusCircleFill, BsXCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useTimezone } from '@/contexts/timezone_context';

import styles from "./styles.module.scss";

const CompletionNotes = ({ completionNotes }) => {
    return (
        <div className={styles.notes}>
            <Icon className={styles.icon} as={BsChatFill} boxSize={4} color={"secondary.darkGray"} />
            <Text>{completionNotes}</Text>
        </div>
    )
}

const AttendanceTaskHistoryItem = ({ task, expanded }) => {
    const navigate = useNavigate();
    const timezoneContext = useTimezone();

    if (expanded) {
        return (
            <div
                className={`${styles.expanded} ${styles.taskContainer}`}
                onClick={() => navigate(`/employee/${task.employee.id}`)}
            >
                <div className={styles.detailsContainer}>
                    <Text className={styles.heading} fontSize={16} fontWeight={500}>{task.taskName}</Text>
                    <div className={styles.tagsContainer}>
                        <div className={styles.tag}>
                            {!!task.completedAt ?
                                <Icon className={styles.icon} as={BsPlusCircleFill} boxSize={5} color={"secondary.green"} /> :
                                <Icon className={styles.icon} as={BsDashCircleFill} boxSize={5} color={"secondary.yellow"} />
                            }
                            <Text>{!!task.completedAt ? "Completed" : "Incomplete"}</Text>
                        </div>
                        <div className={styles.tag}>
                            <Icon className={styles.icon} as={BsPersonCircle} boxSize={5} color={"secondary.darkGray"} />
                            <Text>{task.assignee.name}</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.detailsContainer}>
                    <div className={styles.tagsContainer} style={{ "marginLeft": "0px" }}>
                        <div className={styles.tag}>
                            <Icon className={styles.icon} as={BsCalendarFill} boxSize={5} color={"primary.500"} />
                            <Text>
                                {timezoneContext.formatDate(task.createdAt, "MMM d, yyyy")}
                            </Text>
                        </div>
                        <div className={styles.tag}>
                            <Icon className={styles.icon} as={BsPersonCircle} boxSize={5} color={"primary.500"} />
                            <Text>{task.employee.name}</Text>
                        </div>
                        <div className={styles.tag}>
                            <Icon className={styles.icon} as={BsXCircleFill} boxSize={5} color={"secondary.darkGray"} />
                            <Text>{task.shiftIds.length} missed</Text>
                        </div>
                    </div>
                </div>
                {!!task.completionNotes && <CompletionNotes completionNotes={task.completionNotes} />}
            </div >
        )
    }

    return (
        <div className={styles.taskContainer}>
            <div className={styles.detailsContainer}>
                <Icon className={styles.icon} as={BsCalendarFill} boxSize={5} color={"primary.500"} />
                <Text className={styles.text} fontSize={20} fontWeight={500}>
                    {timezoneContext.formatDate(task.createdAt, "MMM d, yyyy")}
                </Text>
                <div className={styles.tagsContainer}>
                    <div className={styles.tag}>
                        {!!task.completedAt ?
                            <Icon className={styles.icon} as={BsPlusCircleFill} boxSize={5} color={"secondary.green"} /> :
                            <Icon className={styles.icon} as={BsDashCircleFill} boxSize={5} color={"secondary.yellow"} />
                        }
                        <Text>{!!task.completedAt ? "Completed" : "Incomplete"}</Text>
                    </div>
                    <div className={styles.tag}>
                        <Icon className={styles.icon} as={BsPersonCircle} boxSize={5} color={"secondary.darkGray"} />
                        <Text>{task.assignee.name}</Text>
                    </div>
                    <div className={styles.tag}>
                        <Icon className={styles.icon} as={BsXCircleFill} boxSize={5} color={"secondary.darkGray"} />
                        <Text>{task.shiftIds.length} missed</Text>
                    </div>
                </div>
            </div>
            {!!task.completionNotes && <CompletionNotes completionNotes={task.completionNotes} />}
        </div >
    );
}

export default AttendanceTaskHistoryItem;
