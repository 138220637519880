import useSWR from "swr";
import { domainUrl } from "@/utils/fetch_utils";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "@contexts/auth_context";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useTimezone } from "@/contexts/timezone_context";
import { DateTimePicker } from "@/components/DateTimePicker";

export const PayPeriodTypeMappings = {
  weekly: { label: "1-week" },
  biweekly: { label: "2-week" },
  monthly: { label: "4-week" },
  custom: { label: "Custom" },
}
export interface IPayPeriodOption {
  type: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

interface IPayPeriodSelectProps {
  selectedPayPeriod: IPayPeriodOption | null;
  setSelectedPayPeriod: (type: IPayPeriodOption | null) => void;
  payPeriodOptions: Array<IPayPeriodOption>;
  customStartDate: Date;
  customEndDate: Date;
  setCustomStartDate: (newDate: Date) => void;
  setCustomEndDate: (newDate: Date) => void;
}

const PayPeriodSelect = (props: IPayPeriodSelectProps) => {
  const { selectedPayPeriod, setSelectedPayPeriod, payPeriodOptions, customStartDate, customEndDate, setCustomStartDate, setCustomEndDate } = props;
  
  const timezoneContext = useTimezone();
  const [searchParams, setSearchParams] = useSearchParams();

  const setSelectedPayPeriodQueryParams = (newValue) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    currentParams.set("pay_period", newValue?.type);

    let newSelectedPayPeriod = newValue;
    if (newValue?.type === "custom") {
      currentParams.set("custom_start_date", encodeURIComponent(customStartDate.toISOString()));
      currentParams.set("custom_end_date", encodeURIComponent(customEndDate.toISOString()));
      newSelectedPayPeriod = { type: "custom", label: PayPeriodTypeMappings.custom.label, startDate: customStartDate, endDate: customEndDate };
    } else {
      currentParams.delete("custom_start_date");
      currentParams.delete("custom_end_date");
    }

    setSelectedPayPeriod(newSelectedPayPeriod)
    setSearchParams(currentParams);
  }

  useEffect(() => {
    if (selectedPayPeriod?.type === "custom") {
      const currentParams = new URLSearchParams(searchParams.toString());
      currentParams.set("custom_start_date", encodeURIComponent(customStartDate.toISOString()));
      currentParams.set("custom_end_date", encodeURIComponent(customEndDate.toISOString()));
      setSearchParams(currentParams);
      
      // Update the selectedPayPeriod with the new custom date range
      setSelectedPayPeriod({
        type: "custom",
        label: PayPeriodTypeMappings.custom.label,
        startDate: customStartDate,
        endDate: customEndDate
      });
    }
  }, [customStartDate, customEndDate]);

  return (
    <>
      <FormControl mr={2}>
        <FormLabel>Pay Period</FormLabel>
        <Select
          size='md'
          placeholder='Select Pay Period'
          onChange={(event) => setSelectedPayPeriodQueryParams(payPeriodOptions[event.target.value])}
          value={selectedPayPeriod?.type || undefined}>
          {
            Object.keys(payPeriodOptions).map((key) => {
              return <option key={key} value={key}>{payPeriodOptions[key].label}</option>;
            })
          }
        </Select>
      </FormControl>
      { selectedPayPeriod ? (
            <>
              <FormControl mb={4} mr={2}>
                <FormLabel>Start Date</FormLabel>
                <DateTimePicker
                  value={selectedPayPeriod?.startDate}
                  onChange={setCustomStartDate}
                  showTime={false}
                  showDate
                  disabled={selectedPayPeriod?.type !== "custom"}
                />
              </FormControl>
              <FormControl mb={4} mr={2}>
                <FormLabel>End Date</FormLabel>
                <DateTimePicker
                  value={selectedPayPeriod?.endDate}
                  onChange={setCustomEndDate}
                  showTime={false}
                  showDate
                  disabled={selectedPayPeriod?.type !== "custom"}
                />
              </FormControl>
            </>
          ) : null }
    </>
  );
};

export default PayPeriodSelect;
