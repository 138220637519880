import { useAuth } from "@contexts/auth_context";
import {
  Avatar, Card, CardHeader, CardBody, Heading, Flex
} from "@chakra-ui/react";

import styles from "./styles.module.scss";

const Home = () => {
  const authContext = useAuth();
  const userInfo = authContext.state.userToken.user_info;
  const organization = authContext.state.userToken.organization;

  return (
    <div className={styles.container}>
      <Card>
        <CardHeader>
          <Flex>
            <Avatar name={`${userInfo.name}`} style={{marginRight: 10}}/>
            <Heading size='lg'>
              Welcome, {userInfo.name}!
            </Heading>
          </Flex>

        </CardHeader>

        <CardBody>
          <Heading size={'md'}>
            {organization.name}
          </Heading>
        </CardBody>
      </Card>
    </div>
  );
};

export default Home;
