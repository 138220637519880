import useSWR from "swr";

import { Box, Flex, Heading, Image, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";

import { BuildingList } from "@/components/BuildingList";
import { BuildingsByHealth, DaysInRedChart } from "@/components/Charts";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";

import buildingRed from '../../assets/building_red.png';

import styles from "./styles.module.scss";

const BuildingDashboard = () => {
  const authContext = useAuth();
  const theme = useTheme();

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/locations/metrics`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return <FullScreenSpinner />
  }

  const buildingsInRed = data.locations.filter(location =>
    location.healthScores.overall < 0.65
  )

  // If all buildings have 0 revenue, fallback to non revenue displays
  const revenueAvailable = data.locations.reduce(
    (sum, item) => sum + item.revenuePerMonth, 0
  ) > 0
  const revenueInRed = buildingsInRed.reduce(
    (sum, item) => sum + item.revenuePerMonth, 0
  )

  return (
    <Flex
      className={styles.container}
      flexDirection={"column"}
      height="100%"
    >
      <div className={styles.header}>
        <Heading className={styles.text} size="lg">Buildings Health</Heading>
      </div>
      <Flex
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <DaysInRedChart />
        <Box
          backgroundColor="#FFF2F2"
          borderRadius="3xl"
          border={`1px solid ${theme.colors.secondary.red}`}
          padding={"4em 0em 5em 0em"}
          width={"32%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Image src={buildingRed} boxSize="3.5em" />
          <Heading className={styles.text} color={theme.colors.secondary.red} fontSize={"5em"} margin={0}>
            {/* If no revenue available, display count of buildings in red */}
            {revenueAvailable ? `${(Math.round(revenueInRed / 1000)).toLocaleString(
              "en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }
            )}k` : buildingsInRed.length}
          </Heading>
          <Heading className={styles.text} size="lg" margin={0}>{!revenueAvailable && "Buildings "}At Risk</Heading>
        </Box>
        <BuildingsByHealth />
      </Flex>
      <div className={styles.header}>
        <Heading className={styles.text} size="md">Buildings</Heading>
      </div>
      <BuildingList data={data} revenueAvailable={revenueAvailable} />
    </Flex >
  )
}

export default BuildingDashboard;
