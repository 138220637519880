import { Icon, Text } from '@chakra-ui/react';
import { BsClipboardCheck, BsClipboardX } from 'react-icons/bs';

import styles from "./styles.module.scss";

const AttendanceTaskMissing = ({ error = false }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.noTasks}>
        <Icon className={styles.icon} as={error ? BsClipboardX : BsClipboardCheck} boxSize={14} />
        <Text fontSize={18} fontWeight={700}>
          {error ? "Something went wrong fetching tasks" : "No Tasks Yet"}
        </Text>
      </div>
    </div>
  )
}

export default AttendanceTaskMissing;