import GoogleMap from "google-maps-react-markers";
import { useRef } from "react";
import { useTheme, Text } from "@chakra-ui/react";
import { useAuth } from "@contexts/auth_context";

const toLatLng = (location) => ({lat: parseFloat(location.latitude), lng: parseFloat(location.longitude)});

const ShiftMap = ({ shiftLocation, punchLocations, center, resetCenter}) => {
  const allLocations = [shiftLocation, ...punchLocations];
  const mapRef = useRef(null)
  const theme = useTheme();
  const authContext = useAuth();


  /**
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map

    const shiftLatLng = toLatLng(shiftLocation);


    if (authContext.state?.features?.developerFeature) {
      // When the geofence radius is Integer.MAX_VALUE, we don't want to draw the circle
      if (shiftLocation.geofenceRadius && shiftLocation.geofenceRadius > 0 && shiftLocation.geofenceRadius < 10_000) {
        const circle = new maps.Circle({
          strokeColor: theme.colors.blue["400"],
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: theme.colors.blue["400"],
          fillOpacity: 0.2,
          map,
          center: shiftLatLng,
          radius: shiftLocation.geofenceRadius,
        });
      }
    }
  }

  if (mapRef.current && center) {
    mapRef.current.panTo(toLatLng(center))
  }

  if (!shiftLocation.latitude || !shiftLocation.longitude) {
    return <Text> Missing shift location</Text>;
  }

  return (
    <GoogleMap
        apiKey="AIzaSyCFM_UC5V2sF2Z2BNSkDe9lK9arwiZgf0E"
        defaultCenter={toLatLng(shiftLocation)}
        defaultZoom={20}
        onGoogleApiLoaded={onGoogleApiLoaded}
        /*
          When a user
          1. center on an item using the center prop
          2. interact with the map to no longer center that item
          3. center on the same item again using the center prop
          We want to reset the center prop in step 2 to force a re-render in step 3.
         */
        onChange={resetCenter}
      >
      {allLocations.map((location, idx) => {
        const latLng = toLatLng(location);
        // TODO: refactor this to not reuse the same component for shift location and punch location
        return (
          <div
            style={{
              position: "relative",
              width: "fit-content",
              height: "fit-content",
              backgroundColor: location.punch_id == null ? theme.colors.blue["400"] : theme.colors.blackAlpha["600"],
              padding: "4px 8px",
              borderRadius: "4px",
              color: "white",
              fontSize: "12px",
              fontWeight: "bold",
              cursor: 'pointer',
              transform: "translate(-50%, -50%)",
            }}
            key={idx}
            lat={latLng.lat}
            lng={latLng.lng}
          >{location.label}</div>
        )
      })}
    </GoogleMap>
  );
};

export default ShiftMap;
