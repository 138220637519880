import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, FormControl, ModalFooter, FormLabel, Button, useToast } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useAuth } from '@contexts/auth_context';
import { DateTimePicker } from "@/components/DateTimePicker";

const parseDateString = (dateString) => {
  if (!dateString) return null;
  return new Date(dateString);
};

const PunchEditModal = ({ isOpen, shift, onClose, onSave }) => {
  const authContext = useAuth();
  const lunchBreakEnabled = authContext.state?.features?.lunchBreaks;
  const [clockInTime, setClockInTime] = useState<Date | null>(null);
  const [mealStartTime, setMealStartTime] = useState<Date | null>(null);
  const [mealEndTime, setMealEndTime] = useState<Date | null>(null);
  const [clockOutTime, setClockOutTime] = useState<Date | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setClockInTime(parseDateString(shift?.clockInTimestamp));
    setMealStartTime(parseDateString(shift?.punches?.find(punch => punch.punchType === "meal_start" && !punch.discardedAt)?.punchInTimestamp));
    setMealEndTime(parseDateString(shift?.punches?.find(punch => punch.punchType === "meal_end" && !punch.discardedAt)?.punchInTimestamp));
    setClockOutTime(parseDateString(shift?.clockOutTimestamp));
  } , [shift]);

  const handleSave = async () => {
    if (!shift) return;
    toast.closeAll();
    setIsSaving(true);
    authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/shifts/${shift.id}`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clockInTimestamp: clockInTime,
        mealStartTimestamp: mealStartTime,
        mealEndTimestamp: mealEndTime,
        clockOutTimestamp: clockOutTime,
      }),
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      onSave();
    }).catch((error) => {
      toast({
        description: error.message,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    }).finally(() => {
      setIsSaving(false);
    });
  };

  if (!shift) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"md"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Punches</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl mb={4}>
            <FormLabel>Clock In Time</FormLabel>
            <DateTimePicker
              value={clockInTime}
              onChange={setClockInTime}
              showTime={true}
              showDate={true}
            />
          </FormControl>
          {lunchBreakEnabled && (
            <>
              <FormControl mb={4}>
                <FormLabel>Meal Start Time</FormLabel>
                <DateTimePicker
                  value={mealStartTime}
                  onChange={setMealStartTime}
                  showTime={true}
                  showDate={true}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Meal End Time</FormLabel>
                <DateTimePicker
                  value={mealEndTime}
                  onChange={setMealEndTime}
                  showTime={true}
                  showDate={true}
                />
              </FormControl>
            </>
          )}
          <FormControl>
            <FormLabel>Clock Out Time</FormLabel>
            <DateTimePicker
              value={clockOutTime}
              onChange={setClockOutTime}
              showTime={true}
              showDate={true}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isSaving}
            loadingText="Updating punches..."
            onClick={handleSave}>Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PunchEditModal;
