import { useState } from "react";
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Card, CardHeader, CardBody, CardFooter,
  Heading, Text,
  Flex, Spacer, Center, Skeleton,
  Tag, TagLabel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, useTheme,
} from '@chakra-ui/react';

import styles from "./styles.module.scss";


const noShowLineData = [
  {
    id: "No Shows",
    data: [
      { x: "1/14/2023", y: 2 },
      { x: "1/15/2023", y: 4 },
      { x: "1/16/2023", y: 3 },
      { x: "1/17/2023", y: 2 },
      { x: "1/18/2023", y: 0 },
      { x: "1/19/2023", y: 0 },
      { x: "1/20/2023", y: 2 },
      { x: "1/21/2023", y: 2 },
      { x: "1/22/2023", y: 0 },
      { x: "1/23/2023", y: 1 },
      { x: "1/24/2023", y: 0 },
      { x: "1/25/2023", y: 4 },
      { x: "1/26/2023", y: 2 },
      { x: "1/27/2023", y: 2 },
      { x: "1/28/2023", y: 0 },
      { x: "1/29/2023", y: 0 },
      { x: "1/30/2023", y: 0 },
      { x: "1/31/2023", y: 2 },
      { x: "2/1/2023", y: 0 },
      { x: "2/2/2023", y: 1 },
      { x: "2/3/2023", y: 2 },
      { x: "2/4/2023", y: 3 },
      { x: "2/5/2023", y: 3 },
      { x: "2/6/2023", y: 0 },
      { x: "2/7/2023", y: 4 },
      { x: "2/8/2023", y: 1 },
      { x: "2/9/2023", y: 2 },
      { x: "2/10/2023", y: 4 },
      { x: "2/11/2023", y: 2 },
      { x: "2/12/2023", y: 0 },
      { x: "2/13/2023", y: 3 },
      { x: "2/14/2023", y: 0 },
    ]
  }
]

const hoursByWeek = [
  { "date": "Fairview", margin: 1.2 },
  { "date": "Oakridge", margin: 6.4 },
  { "date": "Marpole", margin: -2.1 },
  { "date": "Sunset", margin: -0.7 },
  { "date": "Victoria-Fraserview", margin: 3.2 },
  { "date": "Downtown", margin: 2.6 },
  { "date": "West End", margin: 8.2 },
  { "date": "Riley Park", margin: -6.2 },
].sort((a,b) => a.margin - b.margin)


const hoursBudgetBarData = [
  {
    "date": "1/15-1/21",
    "The Sutter Club": -4,
    "Raley's - 1601 W Capitol Ave": -3,
    "Buehler Engineering Inc": 10,
    "Delta Breeze Records": 1,
    "California Museum": -2,
  },
  {
    "date": "1/22-1/28",
    "The Sutter Club": -4,
    "Raley's - 1601 W Capitol Ave": -1,
    "Buehler Engineering Inc": 0,
    "Delta Breeze Records": 5,
    "California Museum": 0,
  },
  {
    "date": "1/29-2/4",
    "The Sutter Club": -7,
    "Raley's - 1601 W Capitol Ave": -1,
    "Buehler Engineering Inc": -3,
    "Delta Breeze Records": 5,
    "California Museum": 0,
  },
  {
    "date": "2/5-2/11",
    "The Sutter Club": -1,
    "Raley's - 1601 W Capitol Ave": 7,
    "Buehler Engineering Inc": -7,
    "Delta Breeze Records": 5,
    "California Museum": 7,
  },
  {
    "date": "2/12-2/14",
    "The Sutter Club": -1,
    "Raley's - 1601 W Capitol Ave": 1,
    "Buehler Engineering Inc": 0,
    "Delta Breeze Records": 5,
    "California Museum": 0,
  },
]

const MyResponsiveLine = ({ data /* see data tab */ }) => (
  <ResponsiveLine
    data={data}
    margin={{ top: 20, right: 50, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false
    }}
    yFormat=">-.0~r"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -50,
      // legend: 'Date',
      // legendOffset: 36,
      // legendPosition: 'middle'
    }}
    axisLeft={{
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '# No Show',
      legendOffset: -40,
      legendPosition: 'middle',
      format: e => Math.floor(e) === e && e, //https://github.com/plouc/nivo/issues/144
    }}
    pointSize={4}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    colors={{ scheme: 'category10' }}
    curve={"monotoneX"}
    borderColor={{ theme: 'background' }}
  />
);

const MyResponsiveBar = ({ data /* see data tab */ }) => (
  <ResponsiveBar
    data={data}
    keys={[
      "The Sutter Club",
      "Raley's - 1601 W Capitol Ave",
      "Buehler Engineering Inc",
      "Delta Breeze Records",
      "California Museum",
    ]}
    indexBy="date"
    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
    padding={0.3}
    groupMode="grouped"
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={{ scheme: 'pastel1' }}
    // markers={[
    //   {
    //     axis: 'y',
    //     value: 35,
    //     lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2 },
    //     // legend: 'y marker at 300',
    //     // legendOrientation: 'vertical',
    //   },
    // ]}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6
        ]
      ]
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Weeks',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Hours',
      legendPosition: 'middle',
      legendOffset: -40
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6
        ]
      ]
    }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    enableLabel={false}
  />
)

const OperationsDashboard = () => {

  const theme = useTheme();
  console.log(theme)
  const [isLoaded, setIsLoaded] = useState(false);

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  async function demo() {
    await sleep(500);
    setIsLoaded(true);
  }

  demo();

  const supervisors = [
    "Douglas Hill",
    "Gerald Fong",
    "Saagar Deshpande",
    "Michelle Thompkin",
    "Kevin Stewart",
    "Jose Alvarez",
  ];
  const visits = [6, 8, 3, 9, 10, 7];
  const plannedVisits = [12, 16, 13, 18, 17, 14];

  return (
    <div className={styles.container}>

      <Center>
        <Heading noOfLines={1} color={"blackAlpha.800"} style={{marginBottom: 30}}>
          Integrity Operations Breakdown
        </Heading>
      </Center>

      <div>
        <Card>
          <CardHeader>
            <Heading size='md'>Operations Highlights</Heading>
            <Text fontSize='sm'>This Week</Text>
          </CardHeader>

          <CardBody>
            <StatGroup>
              <Stat>
                <StatLabel>Shifts Clocked In Today</StatLabel>
                <StatNumber>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    <Tag size='lg' colorScheme='yellow' borderRadius='full' style={{marginTop: 5, border: "1px solid", borderColor: theme.colors.yellow["400"]}}>
                      <TagLabel>95%</TagLabel>
                    </Tag>
                  </Skeleton>
                </StatNumber>
              </Stat>

              <Stat>
                <StatLabel>Avg. Site Audit Score</StatLabel>
                <StatNumber>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    92/100
                  </Skeleton>
                </StatNumber>
                <StatHelpText style={{paddingTop: 2}}>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    <StatArrow type='increase' />
                    3.70% W/W
                  </Skeleton>
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Account Health</StatLabel>
                <StatNumber>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    79/100
                  </Skeleton>
                </StatNumber>
                <StatHelpText style={{paddingTop: 2}}>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    <StatArrow type='decrease' />
                    9.05% W/W
                  </Skeleton>
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel># of Customer Tickets</StatLabel>
                <StatNumber>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    16
                  </Skeleton>
                </StatNumber>
                <StatHelpText style={{paddingTop: 2}}>
                  <Skeleton isLoaded={isLoaded} style={{width: 100}}>
                    <StatArrow type='increase' />
                    30% W/W
                  </Skeleton>
                </StatHelpText>
              </Stat>

            </StatGroup>
          </CardBody>
        </Card>
      </div>

      <div style={{marginTop: 20}}>

        <Heading fontSize='2xl' style={{marginBottom: 20}}>Daily No Show Count</Heading>

        <Skeleton isLoaded={isLoaded}>

          {/*https://github.com/plouc/nivo/issues/411*/}
          <div style={{height: 300, minWidth: 0, minHeight: 0, width: "99%"}}>
            <MyResponsiveLine data={noShowLineData} />
          </div>
        </Skeleton>
      </div>

      <Flex style={{paddingTop: 20, marginTop: 20}}>
        <div style={{width: "60%", paddingRight: 10}}>

          <Heading fontSize='xl' style={{marginBottom: 20}}>Weekly Budgeted Hours (Over / Under)</Heading>

          <Skeleton isLoaded={isLoaded}>
            {/*https://github.com/plouc/nivo/issues/411*/}
            <div style={{height: 400, minWidth: 0, minHeight: 0, width: "99%"}}>
              <MyResponsiveBar data={hoursBudgetBarData}/>
            </div>
          </Skeleton>
        </div>

        <div style={{width: "50%", paddingLeft: 5, marginRight: 50}}>

          <Heading fontSize='xl' style={{marginBottom: 20}}>Supervisor Site Visits</Heading>

          <Skeleton isLoaded={isLoaded}>
            <TableContainer style={{marginTop: 20}}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Supervisors</Th>
                    <Th isNumeric>Completed</Th>
                    <Th isNumeric>Target</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {
                    supervisors.map((element, index) => (
                      <Tr key={element}>
                        <Td>{element}</Td>
                        <Td isNumeric>{visits[index]}</Td>
                        <Td isNumeric>{plannedVisits[index]}</Td>
                      </Tr>
                    ))
                  }
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Totals</Th>
                    <Th isNumeric>{visits.reduce((pv, cv) => pv + cv, 0)}</Th>
                    <Th isNumeric>{plannedVisits.reduce((pv, cv) => pv + cv, 0)}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Skeleton>
        </div>
      </Flex>

    </div>
  );
};

export default OperationsDashboard;
