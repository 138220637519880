import useSWR from "swr";

import ReactECharts from 'echarts-for-react';
import { Box, Text, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";
import { camelCaseToTitleCase, formatCurrency } from "@/utils/formatting";
import ChartLoadingState from "./loading_state";
import { useTimezone } from "@/contexts/timezone_context";
import styles from "./styles.module.scss";

const BuildingsByHealth = ({ }) => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/health_scores/category_summary`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return <ChartLoadingState
      spinnerSize={"4em"}
      backgroundColor={"white"}
      border={`1px solid ${theme.colors.gray[200]}`}
      height={"100%"}
      width={"32%"}
    />;
  }

  const categoryColors = {
    "atRisk": theme.colors.secondary.red,
    "fair": theme.colors.secondary.yellow,
    "healthy": theme.colors.secondary.green,
  }
  let option = {
    textStyle: {
      fontFamily: "DM Sans"
    },
    grid: {
      top: "3%",
      left: "20%",
      bottom: "10%",
      right: "5%",
    },
    xAxis: {
      type: 'time',
      minInterval: 1000 * 60 * 60 * 24 * 30,
      maxInterval: 1000 * 60 * 60 * 24 * 30,
      axisLabel: {
        color: "black",
        formatter: "{MMM}",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: true,
        interval: 2,
        lineStyle: {
          color: theme.colors.secondary.gray,
          type: [8, 8],
        }
      }
    },
    yAxis: {
      type: 'value',
      name: data.revenueAvailable ? "Total Revenue" : "Number of Buildings",
      nameLocation: "middle",
      nameGap: 50,
      nameTextStyle: {
        color: theme.colors.secondary.darkGray,
      },
      axisLabel: {
        color: theme.colors.secondary.darkGray,
        formatter: val => data.revenueAvailable ? `$${val / 1000}k` : val
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: false,
      }
    },
    tooltip: {
      show: true,
      trigger: "axis",
      confine: true,
      className: styles.toolTip,
      formatter: (params) => {
        return `
        <div style="display: flex; flex-direction: column;">
          <div style="display: flex; justify-content: space-between;">
            <div style="flex: 0 1 auto; text-align: left; font-weight: bold; margin-right: 1em;">Date</div>
            <div style="flex: 1; text-align: right;">${timezoneContext.formatDate(params[0].axisValue, "MMM d")}</div>
          </div>
          ${params.toReversed().map((param =>
          `
            <div style="display: flex; justify-content: space-between;">
              <div style="flex: 0 1 auto; text-align: left; font-weight: bold; margin-right: 1em;">${param.seriesName}</div>
              <div style="flex: 1; text-align: right;">${data.revenueAvailable ? formatCurrency(param?.value[1]) : param?.value[1]}</div>
            </div>
            `
        )).join("")}
        </div>
        `
      },
    },
    series: Object.entries(data.dataSeries).map(([category, points]) => ({
      name: camelCaseToTitleCase(category),
      type: 'line',
      stack: 'Total',
      lineStyle: {
        width: 0
      },
      showSymbol: false,
      itemStyle: {
        color: categoryColors[category],
      },
      areaStyle: {
        opacity: 1,
      },
      smooth: true,
      emphasis: {
        focus: 'series'
      },
      data: points.map(({ timestamp, value }) => [new Date(timestamp * 1000).setHours(0, 0, 0, 0), value])
    }))
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1em"}
      width={"32%"}
      maxHeight={"25em"}
    >
      <Text fontSize={"xl"} fontWeight={"medium"} marginBottom={"0.5em"}>Overall Building Health</Text>
      <ReactECharts option={option} style={{ height: '85%' }} />
    </Box >
  )
}

export default BuildingsByHealth;
