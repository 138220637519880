import { Box, Flex, Icon, List, ListItem, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Text, Link } from "@chakra-ui/react";
import { BsPeopleFill } from "react-icons/bs";
import { useChannelStateContext } from "stream-chat-react";
import { BiSolidPhone } from "react-icons/bi";

const UserList = () => {
    const { channel } = useChannelStateContext();
    const members = Object.values(channel.state.members).sort((a, b) => {
        return (a.user?.name?.toLowerCase() || "") < (b.user?.name?.toLowerCase() || "") ? -1 : 1
    })

    const renderNameAndPhoneNumber = (user) => {
      return (
        <Flex
          alignItems={"center"}
        >
          <Text>
            {user.name}
          </Text>
          {user.phone_number ? (
            <Link href={"tel:" + user.phone_number} style={{ marginLeft: '6px' }}>
              <BiSolidPhone/>
            </Link>
          ) : null}
        </Flex>
      )
    }
    return (
        <Popover isLazy trigger="hover" placement="end-start">
            <PopoverTrigger>
                <Box marginTop="0.2em">
                    <Icon as={BsPeopleFill} boxSize={8} color={"secondary.deepGray"} />
                </Box>
            </PopoverTrigger>
            <Portal>
                <PopoverContent width={"auto"} backgroundColor={"secondary.lightGray"}>
                    <PopoverArrow backgroundColor={"secondary.lightGray"} />
                    <PopoverBody>
                        <List style={{ maxHeight: '600px', overflowY: 'auto'}}>
                            {members.map((member) => (
                                <ListItem >
                                    {renderNameAndPhoneNumber(member.user)}
                                </ListItem>
                            ))}
                        </List>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};

export default UserList;
