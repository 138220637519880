import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  ButtonGroup,
  Input,
  Select,
  Checkbox,
  Box,
  Text,
  Divider,
  useToast,
  FormLabel,
  FormControl,
  Textarea,
} from '@chakra-ui/react';
import { addSeconds, addDays, formatISO } from "date-fns";
import { useAuth } from "./../../contexts/auth_context";
import { ConfirmationModal } from "@/components/ConfirmationModal";
import styles from "./schedule_edit_modal.module.scss";
import { formatDuration } from '@/utils/formatting';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { renderUserName } from "@/utils/formatting";
import { DateTimePicker } from '../DateTimePicker';
import { useTimezone } from '@/contexts/timezone_context';

const NO_SELECTION_OPTION = '';

enum ConfirmationModalType {
  Edit = "Edit",
  Delete = "Delete",
  Create = "Create",
}

const ScheduleEditModal = ({ isOpen, event, assignments, locations, users, close }) => {
  const timezoneContext = useTimezone();
  if (!isOpen) {
    return null;
  }
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const computeInitialCalendarEndDatetime = (event) => {
    // calendarStartDatetime is null for new event creation.
    // Setting initial value as null for CalendarEndDatetime as well to not show anything in the UI.
    if (!event.calendarStartDatetime) {
      return null;
    }
    let calendarEndDatetime = addSeconds(event.calendarStartDatetime, event.calendarDuration);
    // we maintain the invariant that calendarStartDatetime and calendarEndDatetime are always on the same day.
    // Using addDays instead of -86400 here to handle the edge case from DST.
    if (!timezoneContext.isSameDay(calendarEndDatetime, event.calendarStartDatetime)) {
      calendarEndDatetime = addDays(calendarEndDatetime, -1);
    }
    return calendarEndDatetime;
  }

  const authContext = useAuth();
  const toast = useToast();
  const developerFeatureEnabled = authContext.state?.features?.developerFeature;

  const isEdit = event !== null;
  const originalEvent = event ? { ...event, calendarStartDatetime: new Date(event.calendarStartDatetime), daysOfWeek: event.daysOfWeek.sort()} :
  {
    calendarStartDatetime: null,
    calendarDuration: 0,
    previousEmployeeId: null,
    employeeId: null,
    replacementReason: null,
    daysOfWeek: [],
    locationId: null,
    supervisorId: null,
    disableAttendance: false,
    notes: null,
  }
  // applyFromDate is the event date for edit, or today for create
  const defaultApplyFromDate = timezoneContext.startOfDay(originalEvent.calendarStartDatetime || new Date());
  const dateForTimePicker = originalEvent.calendarStartDatetime || new Date();
  const [updatedEvent, setUpdatedEvent] = useState(originalEvent);
  const [calendarEndDatetime, setCalendarEndDatetime] = useState(computeInitialCalendarEndDatetime(updatedEvent));
  const [confirmationModalType, setConfirmationModalType] = useState(null);

  const modalTitle = isEdit ? "Edit schedule" : "Create new schedule";

  let calendarDuration = 0
  if (updatedEvent.calendarStartDatetime && calendarEndDatetime) {
    // Using addDays instead of +86400 here to handle the edge case from DST.
    const actualEndTime = calendarEndDatetime.getTime() < updatedEvent.calendarStartDatetime.getTime() ? addDays(calendarEndDatetime, 1) : calendarEndDatetime;
    calendarDuration = (actualEndTime.getTime() - updatedEvent.calendarStartDatetime.getTime()) / 1000
  }

  const updatedEventWithCalendarDuration = {
    ...updatedEvent,
    calendarDuration: calendarDuration,
  }

  const getNextDayText = () => {
    if (!updatedEvent.calendarStartDatetime || !calendarEndDatetime) {
      return null;
    }
    if (calendarEndDatetime.getTime() >= updatedEvent.calendarStartDatetime.getTime()) {
      return null;
    }
    return "next day";
  }

  const handleFetchError = (error) => {
    toast({
      description: error.message,
      position: "bottom",
      status: "error",
      variant: "left-accent",
      duration: 5000,
      isClosable: true,
    });
  }

  const deleteAssignmentAndClose = () => {
    authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/assignments/${originalEvent.assignmentId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      setConfirmationModalType(null);
      close(true);
    }).catch(handleFetchError)
  }

  const hasChanges = () => {
    return JSON.stringify(originalEvent) !== JSON.stringify(updatedEventWithCalendarDuration)
  }

  const getValidationResult = () => {
    if (!updatedEvent.locationId) {
      return "Please select a building.";
    }
    if (!updatedEvent.supervisorId) {
      return "Please select a supervisor.";
    }
    if (updatedEvent.daysOfWeek.length === 0) {
      return "Please select at least one day.";
    }
    if (!updatedEvent.calendarStartDatetime) {
      return "Please select a start time.";
    }
    if (!calendarEndDatetime) {
      return "Please select an end time.";
    }
    if(calendarDuration <= 0) {
      return "The end time must be after the start time.";
    }
    return null;
  }

  const validateInput = () => {
    toast.closeAll()
    const validationError = getValidationResult();
    if (validationError) {
      toast({
        description: validationError,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  const saveEventAndClose = (applyFromDate, applyToDate, deleteEventsInRange) => {
    if (!validateInput()) {
      return;
    }

    const url = isEdit ? `${import.meta.env.VITE_API_SERVER}/assignments/${originalEvent.assignmentId}` : `${import.meta.env.VITE_API_SERVER}/assignments`;
    authContext.authenticatedFetch(url, {
      method: isEdit ? "put" : "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: {
          ...updatedEventWithCalendarDuration,
          calendarStartDatetime: formatISO(updatedEvent.calendarStartDatetime, { representation: 'complete' }),
        },
        // applyToDate and applyFromDate should both be midnight local time
        applyFromDate: formatISO(applyFromDate, { representation: 'complete' }),
        applyToDate: applyToDate ? formatISO(applyToDate, { applyToDate: 'complete'}) : null,
        deleteEventsInRange,
      }),
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      setConfirmationModalType(null);
      close(true);
    }).catch(handleFetchError);
  }

  const renderNotes = () => {
    if (updatedEvent.notes === null) {
      return (
        <Button
          variant={"ghost"}
          size={"sm"}
          onClick={() => {setUpdatedEvent({...updatedEvent, notes: ""})}}
        >+ Add notes</Button>
      )
    }
    return (
      <>
        <Divider my={6}/>
        <FormControl
          className={styles.fieldContainer}
        >
          <FormLabel className={styles.fieldLabel}>Notes</FormLabel>
          <Textarea
            width={"100%"}
            className={styles.fieldValue}
            value={updatedEvent.notes}
            resize="none"
            onChange={(event) => setUpdatedEvent({...updatedEvent, notes: event.target.value})}
          />
        </FormControl>
      </>
    );
  }

  const renderDebugInfo = () => {
    if (!developerFeatureEnabled) {
      return null;
    }
    return (
      <>
        <Divider my={6}/>
        <FormControl
          className={styles.fieldContainer}
        >
          <FormLabel className={styles.fieldLabel}>Debug info</FormLabel>
          <Textarea
            width={"100%"}
            height={"200"}
            className={styles.fieldValue}
            value={JSON.stringify(updatedEventWithCalendarDuration, null, 2)}
            resize="none"
            readOnly />
        </FormControl>
      </>
    );
  }

  const renderTopSection = () => {
    const location = locations[updatedEvent.locationId];
    return (
      <>
       <FormControl className={styles.fieldContainer}>
        <FormLabel className={styles.fieldLabel}>Building</FormLabel>
        { isEdit ? <Input readOnly variant="unstyled" className={styles.fieldValue} value={location?.name}/> : (
        <Select
          size='md'
          placeholder='Select building'
          onChange={(event) => setUpdatedEvent({...updatedEvent, locationId: parseInt(event.target.value)})}
          value={updatedEvent.locationId || NO_SELECTION_OPTION}>
          {
            Object.values(locations).sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })).map((location) => {
              return <option key={location.id} value={location.id}>{location.name}</option>;
            })
          }
        </Select>)}
      </FormControl>
      <Flex className={styles.fieldContainer}>
        <FormLabel className={styles.fieldLabel}>Address</FormLabel>
        <Text className={styles.fieldValue}>{location?.address || "N/A"}</Text>
      </Flex>
      </>
    )
  }

  const renderButtons = () => {
    if (isEdit) {
      return (
        <ButtonGroup
          justifyContent={"space-between"}
          width={"100%"}
          spacing={4}
          mb={2}
        >
          <Button
            flex={1}
            variant='solid'
            colorScheme='gray'
            textColor='red.500'
            onClick={() => setConfirmationModalType(ConfirmationModalType.Delete)}>
              Delete schedule
          </Button>
          <Button
            flex={1}
            isDisabled={!hasChanges() || originalEvent.isImmutable}
            variant='solid'
            onClick={() => {
              if(validateInput()) {
                setConfirmationModalType(ConfirmationModalType.Edit)
              }
            }}>
            {originalEvent.isImmutable? 'Not editable' : 'Save changes'}
          </Button>
        </ButtonGroup>
      )
    }
    return (
      <ButtonGroup
        justifyContent={"space-between"}
        width={"100%"}
        spacing={4}
        mb={2}
      >
        <Button
          flex={1}
          variant='solid'
          isDisabled={!hasChanges()}
          onClick={() => {
            if(validateInput()) {
              setConfirmationModalType(ConfirmationModalType.Create)
            }
          }}>
            Create schedule
        </Button>
      </ButtonGroup>
    )
  }

  const updateEmployeeIds = (newEmployeeId) => {
    let previousEmployeeId = originalEvent.previousEmployeeId;
    if (originalEvent.employeeId !== null // this excludes the case where the original event is unfilled
      && newEmployeeId !== originalEvent.employeeId) {
      previousEmployeeId = originalEvent.employeeId;
    }
    if (newEmployeeId === previousEmployeeId) {
      previousEmployeeId = null;
    }
    setUpdatedEvent({
      ...updatedEvent,
      employeeId: newEmployeeId,
      previousEmployeeId: previousEmployeeId,
      replacementReason: previousEmployeeId ? updatedEvent.replacementReason : null,
    })
  }

  const renderPreviousCleaner = () => {
    if (!isEdit) {
      return null;
    }
    if (!updatedEvent.previousEmployeeId) {
      return null;
    }
    return (
    <>
      <Divider my={6}/>
      <FormControl
        className={styles.fieldContainer}
        isDisabled={!updatedEvent.previousEmployeeId}
      >
        <FormLabel className={styles.fieldLabel}>Previous cleaner</FormLabel>
        <Input
          width={240}
          className={styles.fieldValue}
          readOnly={true}
          variant="unstyled"
          value={renderUserName(users, updatedEvent.previousEmployeeId, "N/A")}
        >
        </Input>
      </FormControl>
      <FormControl
        isDisabled={!updatedEvent.previousEmployeeId}
        className={styles.fieldContainer}
      >
        <FormLabel className={styles.fieldLabel}>Replacement reason</FormLabel>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Select
            placeholder="Select replacement reason"
            value={updatedEvent.replacementReason || NO_SELECTION_OPTION}
            onChange={event => setUpdatedEvent({...updatedEvent, replacementReason: event.target.value})}
          >
            <option value="NO_LONGER_EMPLOYED">No longer employed</option>
            <option value="REASSIGNED">Reassigned to another location</option>
            <option value="VACATION">Vacation</option>
            <option value="PUBLIC_HOLIDAY">Public holiday</option>
            <option value="PLANNED_SICK_LEAVE">Planned sick leave</option>
            <option value="UNPLANNED_SICK_LEAVE">Unplanned sick leave</option>
            <option value="FAMILY_EMERGENCY">Family emergency</option>
            <option value="OTHER">Other</option>
          </Select>
        </Flex>
      </FormControl>
    </>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={close} size={"xl"} isCentered>
        <ModalOverlay/>
        <ModalContent maxH="90vh">
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody
           overflowY="auto"
          >
            {renderTopSection()}

            <Divider my={6}/>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Supervisor</FormLabel>
              <Select
                size='md'
                placeholder='Select supervisor'
                onChange={(event) => setUpdatedEvent({...updatedEvent, supervisorId: parseInt(event.target.value)})}
                value={updatedEvent.supervisorId || NO_SELECTION_OPTION}>
                {
                  Object.values(users)
                    .filter((user) => user.isSupervisor)
                    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
                    .map((employee) => {
                      return <option key={employee.id} value={employee.id}>{employee.name}</option>;
                    })
                }
              </Select>
            </FormControl>
            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Cleaner</FormLabel>
              <Flex alignItems={"center"}>
                <Select
                  size='md'
                  placeholder='Select cleaner'
                  onChange={(event) => updateEmployeeIds(parseInt(event.target.value))}
                  value={updatedEvent.employeeId || NO_SELECTION_OPTION}>
                  {
                    Object.values(users).sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })).map((employee) => {
                      return <option key={employee.id} value={employee.id}>
                        {employee.name}
                        </option>;
                    })
                  }
                </Select>
              </Flex>
            </Flex>
            <Flex className={styles.fieldContainer} pt={2}>
             <Checkbox isChecked={!updatedEvent.employeeId} onChange={
                (event) => updateEmployeeIds(event.target.checked ? null : updatedEvent.previousEmployeeId)
              }>
                <Text>Unfilled</Text>
              </Checkbox>
            </Flex>
            <Flex className={styles.fieldContainer} pt={2}>
              <Checkbox isChecked={!updatedEvent.disableAttendance} onChange={
                (event) => setUpdatedEvent({...updatedEvent, disableAttendance: !event.target.checked })
              }>
                <Text>Requires clock-in/out</Text>
              </Checkbox>
            </Flex>
            {renderPreviousCleaner()}
            <Divider my={6}/>

            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Repeat on</FormLabel>
              <ButtonGroup variant="outline" spacing="0" isAttached display={"flex"}>
                {days.map((day, index) => {
                  const sundayBasedIndex = (index + 1) % 7;
                  const isSelected = updatedEvent.daysOfWeek.includes(sundayBasedIndex);

                  return (
                    <Button
                      key={sundayBasedIndex}
                      flex={1}
                      variant={isSelected ? "solid" : "outline"}
                      colorScheme={isSelected ? "primary" : "gray"}
                      fontWeight={"normal"}
                      onClick={() => {
                        if (isSelected) {
                          setUpdatedEvent({
                            ...updatedEvent,
                            daysOfWeek: updatedEvent.daysOfWeek.filter((day) => day !== sundayBasedIndex)
                          });
                        } else {
                          setUpdatedEvent({
                            ...updatedEvent,
                            daysOfWeek: [...updatedEvent.daysOfWeek, sundayBasedIndex].sort()
                          });
                        }
                      }}
                    >
                      {day}
                    </Button>
                  )
                })}
              </ButtonGroup>
            </Flex>

            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Time</FormLabel>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Box width={28}>
                  <DateTimePicker
                    value={updatedEvent.calendarStartDatetime}
                    onChange={(date) => date && setUpdatedEvent({...updatedEvent, calendarStartDatetime: new Date(date.setSeconds(0, 0))})}
                    date={dateForTimePicker}
                    showTime={true}
                    showDate={false}
                  />
                </Box>
                <Box flex={1} borderBottom="1px solid #D9DEE0" ml={2} />
                {!formatDuration(calendarDuration) ? null :
                  <Text
                    px={2}>
                    {formatDuration(calendarDuration)}
                  </Text>
                }
                <Box flex={1} borderBottom="1px solid #D9DEE0" mr={2} />
                <Box width={28}>
                  <DateTimePicker
                    value={calendarEndDatetime}
                    onChange={(date) => date && setCalendarEndDatetime(new Date(date.setSeconds(0, 0)))}
                    date={dateForTimePicker}
                    showTime={true}
                    showDate={false}
                  />
                </Box>
              </Flex>
              <Flex h={6} alignItems="center" justifyContent="flex-end" mr={"32px"} mt={1}>
              {!getNextDayText() ? null :
                <>
                  <HiOutlineExclamationCircle color="#6362F8" size={14}/>
                  <Text alignSelf="center" textAlign={"center"} fontSize={12} ml={1}>
                    {getNextDayText()}
                  </Text>
                </>}
              </Flex>
            </Flex>
            {renderNotes()}
            {renderDebugInfo()}
          </ModalBody>

          <ModalFooter>
            {renderButtons()}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ConfirmationModal
        isOpen={confirmationModalType !== null}
        confirmationModalType={confirmationModalType}
        onClose={() => setConfirmationModalType(null)}
        onConfirm={(applyFromDate, applyToDate) => {
          if (confirmationModalType === ConfirmationModalType.Delete) {
            if (!applyFromDate) {
              deleteAssignmentAndClose();
              return;
            }
            saveEventAndClose(applyFromDate, applyToDate, true);
          } else if (confirmationModalType === ConfirmationModalType.Edit) {
            saveEventAndClose(applyFromDate, applyToDate, false);
          } else if (confirmationModalType === ConfirmationModalType.Create) {
            saveEventAndClose(applyFromDate, applyToDate, false);
          }
        }}
        minApplyToDate={addDays(defaultApplyFromDate, 1)}
        defaultApplyToDate={addDays(defaultApplyFromDate, 1)}
        defaultApplyFromDate={defaultApplyFromDate}
        originalEvent={originalEvent}
        updatedEvent={updatedEventWithCalendarDuration}
        users={users}
        locations={locations}
      />
    </>
  );
};

export default ScheduleEditModal;
