import { Box, Spinner } from "@chakra-ui/react";

const ChartLoadingState = ({ spinnerSize, ...props }) => {

  return (
    <Box
      borderRadius="3xl"
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      {...props}
    >
      <Spinner height={spinnerSize} width={spinnerSize} color="purple.500" />
    </Box>
  )
}

export default ChartLoadingState;
