import { useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";

import { Box, Heading, Icon, Text } from "@chakra-ui/react";
import { BsArrowLeftShort, BsTelephoneFill, BsPersonFill } from "react-icons/bs";

import { useAuth } from "@/contexts/auth_context";
import { AttendanceTaskDetail } from "@/components/AttendanceTaskDetail";

import { AttendanceTaskHistory } from "@/components/AttendanceTaskHistory";
import { AttendanceTaskMissing } from "@/components/AttendanceTaskMissing";
import { ErrorPage } from "@/components/ErrorPage";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import { ShiftHistory } from "@/components/ShiftHistory";

import styles from "./styles.module.scss";

const EmployeePage = () => {
    const { employee_id } = useParams();
    const employeeId = parseInt(employee_id || "");
    const authContext = useAuth();
    const userInfo = authContext?.state?.userToken?.user_info;
    const navigate = useNavigate();

    const getUserUrl = (userId: number) => {
        return `${import.meta.env.VITE_API_SERVER}/users/${userId}`
    }
    const getAttendanceTaskUrl = (userId: number) => {
        return `${import.meta.env.VITE_API_SERVER}/mobile/v1/attendance_tasks?employee_id=${userId}`
    }

    const completedTaskCallback = (taskId) => {
        mutate(getAttendanceTaskUrl(employeeId))
    }

    const { data: employeeData, error: employeeError, isLoading: employeeIsLoading } = useSWR(
        getUserUrl(employeeId),
        url => {
            return authContext
                .authenticatedFetch(url)
                .then(response => { return response.json() })
        }
    );

    const { data: attendanceTaskData, error: attendanceTaskError, isLoading: attendanceTaskIsLoading } = useSWR(
        getAttendanceTaskUrl(employeeId),
        url => {
            return authContext
                .authenticatedFetch(url)
                .then(response => { return response.json() })
        }
    );

    const openTasksForUser = !attendanceTaskData ?
        [] :
        Object.values(attendanceTaskData?.tasks)
            .filter((task) => (
                !task.completedAt && task.assignee.id == userInfo?.id
            ))

    const sortedTasks = !attendanceTaskData ?
        [] :
        Object.values(attendanceTaskData.tasks).sort((a, b) => (
            new Date(b.createdAt) - new Date(a.createdAt)
        ))

    if (employeeIsLoading || attendanceTaskIsLoading) {
        return <FullScreenSpinner />;
    }

    // handle network errors
    if (employeeError || attendanceTaskError) {
        return <ErrorPage error={employeeError || attendanceTaskError} />;
    }

    // handle API specific errors
    if (employeeData.error || attendanceTaskData?.error) {
        return <ErrorPage errorMessage={employeeData.error || attendanceTaskData?.error} />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Icon className={styles.icon} onClick={() => navigate(`/clock_in_dashboard/`)} as={BsArrowLeftShort} boxSize={10} color={"primary.900"} />
                <Heading className={styles.text} size="lg">{employeeData.user.name}</Heading>
                <div className={styles.contactInfo}>
                    <div className={styles.details}>
                        <Icon className={styles.icon} as={BsTelephoneFill} boxSize={4} color={"primary.500"} />
                        <Text>{employeeData.user.phoneNumber}</Text>
                    </div>
                    <div className={styles.details}>
                        <Icon className={styles.icon} as={BsPersonFill} boxSize={5} color={"primary.500"} />
                        <Text>{employeeData.user.username}</Text>
                    </div>
                </div>
            </div>
            <Heading className={styles.heading} size="md" fontWeight={500}>Your Open Tasks</Heading>
            {Object.keys(openTasksForUser).length > 0 ?
                Object.values(openTasksForUser).map((task, index) => {
                    return <AttendanceTaskDetail
                        key={index}
                        task={task}
                        shifts={attendanceTaskData?.shifts}
                        completedTaskCallback={completedTaskCallback}
                    />
                }) :
                <AttendanceTaskMissing />
            }
            <Heading className={styles.heading} size="md" fontWeight={500}>Task History</Heading>
            <AttendanceTaskHistory tasks={sortedTasks} expanded={false} />
            <Heading className={styles.heading} size="md" fontWeight={500}>Shift History</Heading>
            <ShiftHistory userId={employeeId} />
            <Box height="1em" />
        </div>
    )
}

export default EmployeePage;
