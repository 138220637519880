import { AuthContextInterface, AuthState } from "../types/auth"
import { Dispatch, createContext, useContext, useEffect, useReducer } from "react";
import { FeatureEndpoint, UserFeatures } from "../types/features"
import jwtDecode, {JwtPayload} from "jwt-decode";

import _ from 'lodash';
import { fromUnixTime } from "date-fns";
import { useCookies } from 'react-cookie';
import { domainUrl } from "@/utils/fetch_utils";

const AuthContext = createContext<AuthContextInterface>({
  signIn: () => {},
  signOut: () => {},
  sendCode: () => {},
  state: {},
  dispatch: () => {},
  authenticatedFetch: () => {},
});

const userFeaturesDefaultState: UserFeatures = {
  mobileClockReview: false,
  lunchBreaks: false,
  geofenceEnforcement: true,
  geofenceWarning: true,
  attestation: true,
  messaging: false,
  developerFeature: false,
  attendanceTask: false,
  punchCorrection: false,
};

const signIn = async (data, dispatch) => {
  let username = data.username;
  let password = data.password;

  let headers: Headers|null = null;
  let statusCode: number|null = null;

  const res = await fetch(`${domainUrl}/users/sign_in.json`, {
    method: 'POST',
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user: {
        username: username,
        password: password,
      }
    }),
  });

  const responseData = await res.json();
  headers = res.headers;
  statusCode = res.status
  const authHeader = headers.get("authorization");

  let userToken = {
    access_token: authHeader,
    user_info: responseData.user_info,
    organization: responseData.organization,
  };

  if (statusCode === 201 && !!authHeader) {
    dispatch({type: "SIGN_IN", token: userToken});
    return "";
  }
  return responseData.error || "Check your username and password before trying again.";
};

const sendCode = async (username) => {
  let statusCode: number|null = null;

  try {
    const res = await fetch(`${domainUrl}/mobile/v1/users/send_otp.json`, {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
      }),
    });

    const responseData = await res.json();
    statusCode = res.status
    if (statusCode === 200) {
      return { message: responseData.message || "Login code was sent.", isError: false };
    } else {
      return { message: responseData.error || "Failed to request login code. Please try again later.", isError: true };
    }
  } catch (error) {
    return { message: "Failed to request login code. Please try again later.", isError: true };
  }
};

const refreshUserFeatures = async (state: AuthState, dispatch: Dispatch<any>) => {
  fetch(`${domainUrl}/mobile/v1/users/features`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": `${state.userToken?.access_token}`,
      }
    }
  )
    .then((response: Response) => {
      return response.json();
    })
    .then((output: FeatureEndpoint) => {
      dispatch({
        type: "UPDATE_WITH_USER_FEATURES_RESPONSE",
        ...output,
      });
    })
    .catch((error: any) => {
      console.error(error);
    });
}

const signOut = (state, dispatch) => {

  fetch(`${domainUrl}/users/sign_out.json`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": `${state.userToken.access_token}`,
    },
  }).then(( response: Response ) => {
    let statusCode = response.status;
    if (statusCode === 204) {
      dispatch({type: "SIGN_OUT"});
    } else {
      console.log("LOG OUT FAILED");
    }
  });
};

const AuthProvider = ({children}) => {
  let initialState = {
    isLoading: true,
    isSignout: false,
    userToken: null,
    features: userFeaturesDefaultState,
    streamConfig: null,
    timezone: null,
  };

  const [cookies, setCookie, removeCookie] = useCookies(['userToken']);
  // Check if the user has a token set in their cookies and if valid / available, set the state
  // This should allow the user to skip the SignIn flow.
  if (cookies.userToken) {
    initialState.userToken = cookies.userToken;
  }

  const authReducer = (prevState, action) => {

    switch (action.type) {
      case "RESTORE_TOKEN":
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case "SIGN_IN":
        const token: string = action.token.access_token.split(" ")[1];
        const decoded = jwtDecode<JwtPayload>(token); // Returns with the JwtPayload type
        const expiresAtDate = fromUnixTime(decoded?.exp);
        setCookie('userToken', action.token, { path: '/', sameSite: "strict", expires: expiresAtDate });

        return {
          ...prevState,
          isSignout: false,
          userToken: action.token,
        };
      case "UPDATE_WITH_USER_FEATURES_RESPONSE":
        return {
          ...prevState,
          features: { ...prevState.features, ...action.features },
          streamConfig: { token: action.streamToken, apiKey: action.streamApiKey },
          timezone: action.timezone,
        };
      case "SIGN_OUT":
        removeCookie("userToken", { path: '/', sameSite: "strict" });

        return {
          ...prevState,
          isSignout: true,
          userToken: null,
        };
    }
  };

  const authenticatedFetch = (url: RequestInfo | URL, options?: RequestInit): Promise<Response> => {

    const defaults = {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${state.userToken.access_token}`,
      }
    }

    options = _.merge({}, defaults, options)

    if (options?.headers && options.headers['Content-Type'] === 'multipart/form-data') {
      delete options.headers['Content-Type'];
    }

    return fetch(url, options).then(async (response) => {
      if (response.status === 401) {
        dispatch({type: "SIGN_OUT"});
      }
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error);
      }
      return response;
    });
  }

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    refreshUserFeatures(state, dispatch);
  }, [state.userToken])

  const value = {
    signIn,
    signOut,
    sendCode,
    state,
    dispatch,
    authenticatedFetch,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext');
  }
  return context;
};

export {AuthProvider, useAuth};
