import {
  Tag,
  TagLabel,
} from "@chakra-ui/react";

export const StatusMappings = {
  completed: "Completed",
  clocked_in: "Clocked In",
  upcoming: "Upcoming",
  no_show: "No Show",
  not_tracked: "Not Tracked",
  not_installed: "Not Installed"
}

const ShiftStatus = ({eventStatus}: {eventStatus: string}) => {
  switch (eventStatus) {
    case "completed":
      return (
        <Tag size="sm" colorScheme="green" borderRadius="full">
          <TagLabel>{StatusMappings[eventStatus]}</TagLabel>
        </Tag>
      );
    case "clocked_in":
      return (
        <Tag size="sm" colorScheme="blue" borderRadius="full">
          <TagLabel>{StatusMappings[eventStatus]}</TagLabel>
        </Tag>
      );
    case "upcoming":
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>{StatusMappings[eventStatus]}</TagLabel>
        </Tag>
      );
    case "no_show":
      return (
        <Tag size="sm" colorScheme="red" borderRadius="full">
          <TagLabel>{StatusMappings[eventStatus]}</TagLabel>
        </Tag>
      );
    case "not_tracked":
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>{StatusMappings[eventStatus]}</TagLabel>
        </Tag>
      );
    case "not_installed":
      return (
        <Tag size="sm" colorScheme="yellow" borderRadius="full">
          <TagLabel>{StatusMappings[eventStatus]}</TagLabel>
        </Tag>
      );
    default:
      return (
        <Tag size="sm" colorScheme="gray" borderRadius="full">
          <TagLabel>{eventStatus}</TagLabel>
        </Tag>
      );
  }
}

export default ShiftStatus;
