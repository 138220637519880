import useSWR from "swr";

import ReactECharts from 'echarts-for-react';
import { Box, Flex, Table, TableContainer, Tbody, Text, Td, Th, Thead, Tr, useTheme } from "@chakra-ui/react";

import { useAuth } from "@/contexts/auth_context";
import ChartLoadingState from "./loading_state";
import { useTimezone } from "@/contexts/timezone_context";


import styles from "./styles.module.scss";

const InspectionScores = ({ scores, users }) => {
  const theme = useTheme()
  const timezoneContext = useTimezone();
  const data = scores.map((score) =>
    [
      new Date(score.timestamp * 1000).setHours(0, 0, 0, 0),
      Math.round(score.value * 100),
      score.userId,
    ]
  )

  let option = {
    textStyle: {
      fontFamily: "DM Sans"
    },
    grid: {
      top: "5%",
      bottom: "10%",
    },
    xAxis: {
      type: "time",
      minInterval: 1000 * 60 * 60 * 24 * 30,
      maxInterval: 1000 * 60 * 60 * 24 * 30,
      min: function (value) {
        return Date.now() - 1000 * 60 * 60 * 24 * 90;
      },
      max: function (value) {
        return Date.now();
      },
      axisLabel: {
        color: "black",
        formatter: "{MMMM}",
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
          type: [8, 8],
        }
      }
    },
    yAxis: {
      type: "value",
      max: 110,
      axisLabel: {
        color: theme.colors.secondary.darkGray,
        formatter: function (value, index) {
          return (value % 20 === 0) ? value : "";
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray,
        }
      },
      splitLine: {
        show: false,
      }
    },
    visualMap: [{
      type: "piecewise",
      dimension: 1,
      pieces: [
        { min: 80 },
        { min: 65, max: 80 },
        { max: 65 },
      ],
      inRange: {
        color: [
          theme.colors.secondary.red,
          theme.colors.secondary.yellow,
          theme.colors.secondary.green,
        ],
        symbolSize: [36, 36, 36],
      },
      show: false,
    }],
    tooltip: {
      show: true,
      trigger: "item",
      position: "bottom",
      confine: true,
      className: styles.toolTip,
      formatter: (params) => {
        return `
        <b>${timezoneContext.formatDate(params.data[0], "MMM d")}</b><br/>
        <p>
          Inspected by ${users[params.data[2]].name}
        </p>
        `
      },
    },
    series: [
      {
        data: data,
        type: "scatter",
        symbolSize: 40,
        label: {
          color: "white",
          fontWeight: "bold",
          fontSize: 16,
          show: true,
          formatter: function (value) {
            value[1]
          },
        },
        labelLayout: {
          dy: 1,
          align: "center",
          verticalAlign: "middle",
        },
      }
    ]
  }

  return (
    <Box
      borderRadius="3xl"
      backgroundColor="white"
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.5em"}
      width={"49%"}
      maxHeight={"25em"}
    >
      <Text fontSize={"lg"} fontWeight={"medium"} marginBottom={"0.5em"}>Inspection Scores</Text>
      <ReactECharts option={option} style={{ height: '100%' }} />
    </Box>
  )
}

const RepeatProblemAreas = ({ areas }) => {
  const theme = useTheme()

  return (
    <Box
      borderRadius={"3xl"}
      backgroundColor={"white"}
      border={`1px solid ${theme.colors.gray[200]}`}
      padding={"1.5em"}
      width={"49%"}
      maxHeight={"25em"}
    >
      <Text fontSize={"lg"} fontWeight={"medium"} marginBottom={"0.5em"}>Repeat Problem Areas</Text>
      <TableContainer
        border={`1px solid ${theme.colors.secondary.gray}`}
        borderRadius={"0.5em"}
      >
        <Table
          fontSize={"0.85em"}
        >
          <Thead backgroundColor={theme.colors.secondary.lightGray}>
            <Tr>
              <Th
                fontSize={"1em"}
                fontWeight={500}
                color={theme.colors.secondary.darkGray}
                width={"70%"}
              >Area</Th>
              <Th
                fontSize={"1em"}
                fontWeight={500}
                color={theme.colors.secondary.darkGray}
              >Times Reported</Th>
            </Tr>
          </Thead>
          <Tbody >
            {areas.sort((a, b) => b.value - a.value).slice(0, 4).map(area =>
              <Tr
                borderTop={`1px solid ${theme.colors.secondary.gray}`}
                borderBottom={""}
                key={area.name}
              >
                <Td>{area.name}</Td>
                <Td textAlign={"center"}>{area.value}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const InspectionsCharts = ({ locationId }) => {
  const authContext = useAuth();
  const theme = useTheme();

  const { data: data, error: error, isLoading: isLoading } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/locations/${locationId}/metrics/inspections`,
    url => {
      return authContext
        .authenticatedFetch(url)
        .then(response => { return response.json() })
    }
  );

  if (error || isLoading) {
    return (
      <Flex flexDirection={"row"} justifyContent={"space-between"} minHeight={"20em"}>
        <ChartLoadingState
          spinnerSize={"4em"}
          backgroundColor={"white"}
          border={`1px solid ${theme.colors.gray[200]}`}
          height={"100%"}
          width={"49%"}
        />
        <ChartLoadingState
          spinnerSize={"4em"}
          backgroundColor={"white"}
          border={`1px solid ${theme.colors.gray[200]}`}
          height={"100%"}
          width={"49%"}
        />
      </Flex>
    )
  }


  return (
    <Flex flexDirection={"row"} justifyContent={"space-between"} minHeight={"20em"}>
      <InspectionScores scores={data.dataSeries.inspectionScores} users={data.users} />
      <RepeatProblemAreas areas={data.dataSeries.problemAreas} />
    </Flex>
  )
}

export default InspectionsCharts;
