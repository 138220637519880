import { useAuth } from "@contexts/auth_context";
import { useTheme, Flex, Spinner } from "@chakra-ui/react";
import ReactECharts from 'echarts-for-react';
import useSWR from 'swr';
import { ErrorView } from "@/components/ErrorView";
import { useTimezone } from "@/contexts/timezone_context";
import { formatBudgetActualHourText, formatBudgetActualOverUnderText, formatBudgetActualPercentageText, calculateBudgetPercentage } from "@/utils/labor_hour_utils";

const BudgetActualChart = () => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();

  const { data, error, isLoading } = useSWR<{ dailyBreakdown: any[] }>(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/real_time_labor/budget_actual/daily_breakdown?period=LAST_TWO_WEEKS`,
    async (url) => {
      const response = await authContext.authenticatedFetch(url);
      if (!response.ok) throw new Error('Failed to fetch data');
      return response.json();
    }
  );

  const dailyBudgetActual = data?.dailyBreakdown.map((item) => ({
    date: timezoneContext.parse(item.date),
    budgetHours: item.budgetHours,
    actualHours: item.actualHours,
    overBudget: Math.max(item.actualHours - item.budgetHours, 0),
    underBudget: Math.max(item.budgetHours - item.actualHours, 0)
  })) ?? [];

  if (error) return <ErrorView message="Failed to load budget/actual data" />;
  if (isLoading) return <Flex justifyContent="center" alignItems="center" height="100%"><Spinner size="xl" color="purple.500" /></Flex>;

  const keys = ['budgetHours', 'overBudget', 'underBudget'];
  const series = keys.map(key => ({
    name: key,
    type: 'bar',
    stack: 'total',
    barWidth: '50%',
    data: dailyBudgetActual.map(item => item[key]),
    itemStyle: {
      decal: {
        rotation: Math.PI - Math.PI / 3.5,
        dashArrayX: [1, 0],
        dashArrayY: 10,
        color: 'rgba(255, 255, 255, 0.08)'
      }
    }
  }));

  const stackEnd: number[] = [];

  dailyBudgetActual.forEach((_, dayIndex) => {
    for (let seriesIndex = series.length - 1; seriesIndex >= 0; seriesIndex--) {
      if (series[seriesIndex].data[dayIndex]) {
        stackEnd[dayIndex] = seriesIndex;
        break;
      }
    }
  });

  series.forEach((seriesItem, seriesIndex) => {
    seriesItem.data = seriesItem.data.map((value, dayIndex) => {
      const isEnd = stackEnd[dayIndex] === seriesIndex;
      const topBorderRadius = isEnd ? 4 : 0;
      return {
        value,
        itemStyle: {
          borderRadius: [topBorderRadius, topBorderRadius, 0, 0]
        }
      };
    });
  });

  const option = {
    grid: {
      left: '4%',
      right: '4%',
      bottom: '8%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params: any[]) {
        const formattedDate = timezoneContext.formatDate(dailyBudgetActual[params[0].dataIndex].date, 'MMM dd, yyyy');
        const actualHours = params[0].value + params[1].value;
        const budgetHours = params[0].value + params[2].value;
        const percentDifference = calculateBudgetPercentage(actualHours, budgetHours);

        let percentageDisplay = '';
        if (percentDifference !== null && percentDifference !== 0) {
          const color = percentDifference > 0 ? theme.colors.secondary.red : theme.colors.secondary.gray;
          percentageDisplay = `<div>
            <span style="display:inline-flex;align-items:center;font-size:14px;font-weight:500">
              <span style="display:inline-block;width:16px;height:16px;background-color:${color};margin-right:10px;border-radius:4px"></span>
              ${formatBudgetActualPercentageText(percentDifference)} ${formatBudgetActualOverUnderText(percentDifference)}
            </span>
          </div>`;
        }

        return `<div style="padding: 6px">
          <div style="font-size:10px;margin-bottom:8px">${formattedDate}</div>
          ${percentageDisplay}
          <div style="font-size:12px">${formatBudgetActualHourText(actualHours, budgetHours)}</div>
        </div>`;
      },
    },
    xAxis: {
      type: 'category',
      data: dailyBudgetActual.map(item => timezoneContext.formatDate(item.date, 'MMM dd')),
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: theme.colors.secondary.gray
        }
      },
      axisLabel: {
        fontWeight: '500',
        color: theme.colors.secondary.dark
      }
    },
    yAxis: {
      type: 'value',
      name: 'Hours',
      nameLocation: 'middle',
      nameGap: 50,
      splitLine: {
        show: false
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: theme.colors.secondary.gray
        }
      },
      nameTextStyle: {
        color: theme.colors.secondary.darkGray
      },
      axisLabel: {
        color: theme.colors.secondary.darkGray
      },
      axisTick: {
        show: false
      }
    },
    series: series,
    color: [
      theme.colors.primary[500],
      theme.colors.secondary.red,
      theme.colors.secondary.gray
    ]
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: '400px', width: '100%' }}
    />
  );
};

export default BudgetActualChart;
