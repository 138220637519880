import { useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Checkbox,
  VStack,
  Menu,
  MenuButton,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  MenuList,
  Button,
  Divider,
  ButtonGroup,
  Flex,
  Box,
  Badge,
  Text,
  useTheme,
  Input,
} from "@chakra-ui/react";
import { HiChevronDown } from 'react-icons/hi';

function EventFilter({ events, eventIdSetsFromOtherFilters, getFilterKey, getEventId, convertFilterKeyToLabel, updateEvent, filterName, isLoading, supplementKeys = [], width = 200, visible = true}) {
  const theme = useTheme();
  const inputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  // Compute number of events for each filter key
  const filterEventCounts = new Map();
  events.forEach((event) => {
    const filterKey = getFilterKey(event);
    const count = filterEventCounts.get(filterKey) || 0;
    const notFilteredByOtherFilters = eventIdSetsFromOtherFilters.every(eventIdSet => eventIdSet.size > 0 ? eventIdSet.has(getEventId(event)) : true);

    filterEventCounts.set(filterKey, notFilteredByOtherFilters ? count + 1 : count);
  });
  supplementKeys.forEach((key) => {
    filterEventCounts.set(key, filterEventCounts.get(key) || 0);
  })
  const allFilterKeys = Array.from(filterEventCounts.keys()).sort((a, b) => convertFilterKeyToLabel(a).localeCompare(convertFilterKeyToLabel(b), 'en', { sensitivity: 'base' }));
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectOnlyRowIdx, setSelectOnlyRowIdx] = useState(-1);
  const [searchQuery, setSearchQuery] = useState('');
  const filteredFilterKeys = searchQuery.trim().length > 1 ? allFilterKeys.filter((filterKey) => (filterKey).toLowerCase().includes(searchQuery.toLowerCase())) : allFilterKeys;

  useEffect(() => {
    const searchParamsForFilter = searchParams.get(filterName);
    if (searchParamsForFilter === null) {
      setSelectedFiltersAndUpdateEvents(allFilterKeys);
    } else if (searchParamsForFilter === '') {
      setSelectedFiltersAndUpdateEvents([]);
    } else {
      setSelectedFiltersAndUpdateEvents(searchParamsForFilter.split('|').map(decodeURIComponent));
    }
  }, []);

  const setSelectedFiltersAndUpdateEvents = (updatedFilters) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    if (updatedFilters.length == allFilterKeys.length) {
      // when all fitlers are selected, we remove the query param from the URL.
      // Note that this is different from the case when no filters are selected, where the query param is set to an empty string like `?Building=`
      currentParams.delete(filterName);
    } else {
      currentParams.set(filterName, updatedFilters.map(encodeURIComponent).join('|'));
    }
    setSearchParams(currentParams);

    setSelectedFilters(updatedFilters);

    const newSet = new Set(events
      .filter((event) => updatedFilters.includes(getFilterKey(event)))
      .map((event) => getEventId(event)))

    updateEvent(newSet);
  }

  const handleCheckbox = (filterKey) => {
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(filterKey)) {
      updatedFilters = updatedFilters.filter((key) => key !== filterKey);
    } else {
      updatedFilters.push(filterKey);
    }

    setSelectedFiltersAndUpdateEvents(updatedFilters);
  }

  const getFilterTitle = () => {
    if (isLoading) {
      return <Text>Loading ...</Text>;
    }
    // when all filters are selected (the default state)
    if (selectedFilters.length === allFilterKeys.length) {
      return <Text>{filterName}</Text>;
    }
    if (selectedFilters.length === 0) {
      return <Text>Nothing selected</Text>;
    }
    const selectedLabel = convertFilterKeyToLabel(selectedFilters[0]);
    if (selectedFilters.length === 1) {
      return <Text>{selectedLabel?.length > 20 ? selectedLabel.slice(0, 20) + '...' : selectedLabel}</Text>;
    }
    return (
      <>
        <Text mr={2}>{selectedLabel?.length > 18 ? selectedLabel.slice(0, 18) + '...' : selectedLabel}</Text>
        <Text fontWeight="bold">+{selectedFilters.length - 1}</Text>
      </>
    )
  }

  return (
    <Menu
      onOpen={() => {
        setSearchQuery('');
        setSelectOnlyRowIdx(-1);
        // delaying to avoid conflict with the auto focus of the menu itself
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, 0);
      }}
    >
      <MenuButton
        as={Button}
        colorScheme="gray"
        variant="outline"
        fontWeight="normal"
        minW={width}
        maxW={width}
        size="sm"
        rightIcon={<HiChevronDown />}
        borderRadius="md"
        borderWidth="1px"
        isDisabled={isLoading}
        visibility={visible ? 'visible' : 'hidden'}
        _expanded={{ bg: 'purple.400' }}
      >
        <Flex justifyContent="space-between">
          {getFilterTitle()}
        </Flex>
      </MenuButton>
      <MenuList style={{ padding: 0 }}>
        <Card style={{ padding: 0 }}>
          <CardHeader py={2} pl={4} pr={6}>
            <Input
                value={searchQuery}
                variant="flushed"
                ref={inputRef}
                placeholder={'Search ' + filterName.toLowerCase() + '...'}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
          </CardHeader>
          <CardBody style={{ padding: 0 }}>
            <VStack overflow="auto" align="stretch" maxH="40vh" px={4} py={2}>
              {filteredFilterKeys.map((filterKey, idx) => {
                const label = convertFilterKeyToLabel(filterKey);
                const isChecked = selectedFilters.includes(filterKey);
                const eventCount = filterEventCounts.get(filterKey);
                const displaySelectOnly = selectOnlyRowIdx === idx;
                return (
                  <Flex
                    key={`filter-${idx}`}
                    justify="space-between"
                    align="center"
                    onMouseEnter={() => setSelectOnlyRowIdx(idx)}
                    onMouseLeave={() => setSelectOnlyRowIdx(-1)}
                  >
                    <Flex align="center">
                      <Checkbox
                        isChecked={isChecked}
                        onChange={() => handleCheckbox(filterKey)}
                        style={{ marginRight: '5px' }}
                      >
                        {label}
                      </Checkbox>
                      <Text
                        onClick={() => setSelectedFiltersAndUpdateEvents([filterKey])}
                        style={{
                          fontSize: '12px',
                          color: theme.colors.blackAlpha["500"],
                          userSelect: 'none',
                          visibility: displaySelectOnly ? 'visible' : 'hidden',
                          pointerEvents: displaySelectOnly ? 'auto' : 'none',
                          cursor: displaySelectOnly ? 'pointer' : 'default',
                        }}
                      >
                        select only
                      </Text>
                    </Flex>
                    <Box>
                      <Badge ml={2}>{eventCount}</Badge>
                    </Box>
                  </Flex>
                );
              })}
            </VStack>
          </CardBody>

          <Divider />

          <CardFooter style={{ padding: 10 }}>
            <ButtonGroup spacing={10}>
              <Button
                variant="ghost"
                colorScheme="purple"
                size="sm"
                onClick={() => setSelectedFiltersAndUpdateEvents(filteredFilterKeys)}
              >
                Select All
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </MenuList>
    </Menu>
  );
}

export default EventFilter;
